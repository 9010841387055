import httpClient from '../../HTTPService';
import './User.css';
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Loding from '../../Component/Loding/Loding';
import moment from 'moment';

const User = () => {
  const [offsetValue, setoffsetValue] = useState(0);
  const [userList, setUserList] = useState([]);
  const [originalUserList, setOriginalUserList] = useState([])
  const [getLimit, setLimit] = useState(10);
  const [deleteUser, setDeleteUser] = useState();
  const [searchtext, setsearchtext] = useState('')
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1);
  const items = Array.from({ length: totalCount }, (_, index) => `Item ${index + 1}`);
  const itemsPerPage = 10;
  const location = useLocation()
  const totalPages = Math.ceil(items.length / getLimit);
  const startPage = Math.max(1, currentPage - Math.floor(5 / 2));
  const endPage = Math.min(totalPages, currentPage + Math.floor(5 / 2));
  const [filter, setfilter] = useState(3)

  useEffect(() => {
    if (location?.state?.name != undefined) {
      setfilter(0)
    } else if (location?.state?.unpaid != undefined) {
      setfilter(4)
    }
  }, [location])
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const startIndex = (currentPage - 1) * getLimit;
  const currentItems = items.slice(startIndex, startIndex + getLimit);

  useEffect(() => {
    getUser(searchtext, currentPage, getLimit)
  }, [currentPage, getLimit])

  const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    console.log(currentPage, 'current pages...')
    return (
      <div className="pagination">
        {currentPage > 1 && (
          <button className="pagination-btn" onClick={() => onPageChange(currentPage - 1)}>
            <i className="fa fa-chevron-left" aria-hidden="true"></i>
          </button>
        )}
        {startPage > 1 && (
          <button className="pagination-btn" onClick={() => onPageChange(1)}>
            1
          </button>
        )}
        {startPage > 2 && <span className="pagination-ellipsis">...</span>}
        {pages.slice(startPage - 1, endPage).map(page => (
          <button
            key={page}
            className={`pagination-btn ${page === currentPage ? 'active' : ''}`}
            onClick={() => onPageChange(page)}
          >
            {page}
          </button>
        ))}
        {endPage < totalPages - 1 && <span className="pagination-ellipsis">...</span>}
        {endPage < totalPages && (
          <button className="pagination-btn" onClick={() => onPageChange(totalPages)}>
            {totalPages}
          </button>
        )}
        {currentPage < totalPages && (
          <button className="pagination-btn" onClick={() => onPageChange(currentPage + 1)}>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </button>
        )}
      </div>
    );
  };
  const changeStatus = async (data) => {
    try {

      const result = await httpClient.post('/admin/users/change-status', {

        id: data?.id,
        status: data?.status
      }, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      // console.log(result.data);
      toast.success("Status Change Successfully!")
      getUser(searchtext, currentPage, getLimit);
    } catch (error) {
      toast.error(error.message)

    }
  }
  const searchtxt = () => {
    getUser(searchtext, 1, getLimit);
  }
  const getUser = async (search_name, pagecount, limit) => {
    try {
      const result = await httpClient.post('/admin/users-list', {
        search_name,
        search_status: location?.state?.name != undefined ? location?.state?.name
          : (filter == 3 || filter == 4) ? null : filter,
        search_subscription_status: filter == 4 ? 0 : null,
        offset: pagecount, limit: limit
      }, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (result.data.status && result.data.data.length !== 0) {
        //  toast.success("User featch Successfully!")
        setUserList(result.data.data)
        // if (currentPage === 0) {
        setTotalCount(result.data?.total_count)
        location.state = null

        // } else {
        //   setTotalCount(totalCount)
        // }
        // console.log(result.data.data);
      }
      if (result.data.data.length === 0) {
        // toast.error("No user Found")
        setUserList(result.data.data)
      }
    } catch (error) {
      // toast.error(error.message)
    }


  }
  // filterd data
  useMemo(() => {
    // console.log(filter, 'filterd...')

    // if (filter == 4) {
    //   let _filterdata = [...originalUserList].filter(_fitem => _fitem.is_paid_member == false)
    //   console.log(_filterdata, filter, 'filted data..')
    //   setUserList(_filterdata)
    // }
    // else if (filter != 3) {
    //   let _filterdata = [...originalUserList].filter(_fitem => _fitem.active == filter)
    //   console.log(_filterdata, filter, 'filted data..')
    //   setUserList(_filterdata)
    // } else if (filter == 3) {
    //   console.log('set orginal')
    //   setUserList(originalUserList)
    // }

    getUser()
    return () => { }

  }, [filter, originalUserList])

  const deleteBtn = (id) => {

    setDeleteUser(id)
    document.getElementById('modalpop').classList.add('open')
  }
  const closeBtn = () => {
    document.getElementById('modalpop').classList.remove('open')
  }
  const yesDelete = async () => {
    try {
      const result = await httpClient.post('/admin/users-delete', { id: deleteUser }, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      console.log(result.data);

      if (result.data.status == true) {
        toast.success("User Delete Successfully!")
        getUser();
        closeBtn();
      }
      if (result.data.status == false) {
        toast.error(result.data.message);
        closeBtn();
      }
      // if(result.data.data.length === 0){
      //  toast.error("Something went worng")
      //  getUser();
      //  closeBtn();
      // }
    } catch (error) {
      toast.error(error.message)
    }

  }
  return (
    <div className='usersec'>
      <ToastContainer 
     autoClose={1000}
      />
      <div class="row">
        <div class="col-md-12">
          <div class="search-box">
            <div class="searchtct ">

              <i class="fa fa-filter" aria-hidden="true"></i>
              <select
                value={filter}
                // className='ml-3'
                // disabled={chooseFldType === '' ? true : false}
                onChange={(val) => { setfilter(val.target.value) }}
              //   className={(isrequired && labelvalue === null) ? 'required' :'' }
              >
                <option value={3} >All</option>
                <option value={0}>Inactive</option>
                <option value={1}>Active</option>
                <option value={4}>Unpaid</option>
              </select>

            </div>
            <div class="searchtct">

              <i class="fa fa-search" aria-hidden="true"></i>
              <input type="text" name="text" placeholder="Search..."
                onChange={(val) => setsearchtext(val.target.value)}
                onKeyDown={() => {
                  if (searchtext === '') {
                    getUser(searchtext, currentPage, getLimit)
                  }
                }}
              />
              <button type='button' className='searchBtn' onClick={searchtxt}>Search</button>


            </div>

          </div>
          {userList ? <div>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>SL NO</th>
                    <th>Member Code</th>
                    <th>Name</th>
                    <th>Contact</th>
                    <th>Email</th>
                    <th>Purchase Count</th>
                    <th>Referred By</th>
                    <th>Activation Date & Time</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>

                  {userList?.map((item, i) => {
                    return <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{item?.member_code ? item?.member_code : "--"}</td>
                      <td>{item?.name ? item?.name : "--"}</td>
                      <td>{item?.phone ? item?.phone : "--"}</td>
                      <td>{item?.email != null ? item?.email : "--"}</td>
                      <td>{item?.purchase_count != null ? item?.purchase_count : "--"}</td>
                      <td>{item?.referred_by ? item?.referred_by : "--"}</td>
                      <td>{item?.joing_date ? `${moment(item?.joing_date).format('DD-MM-YYYY')} ${moment(item?.joing_date).format('LT')}` : "--"}</td>
                      <td>
                        <div
                          className={`switch ${item?.active === 1 ? 'on' : 'off'}`}
                          onClick={() => changeStatus(
                            {
                              id: item?.id,
                              status: item?.active === 1 ? 0 : 1
                            }
                          )}
                          role="switch"
                          aria-checked={item?.active}
                          aria-label="Toggle switch"
                        >
                          <div className="switch-handle" />
                        </div>

                        {/* <span onClick={() => { changeStatus(item?.id) }} className={item?.active == 1 ? "active curso" : "inactive  curso"}>{item?.active == 1 ? "Active" : "Inactive"}</span> */}
                      </td>
                      <td>
                        <div class="action">
                          {/* <Link><i class="fa fa-user" aria-hidden="true"></i></Link>
                          <Link><i class="fa fa-lock" aria-hidden="true"></i></Link> */}
                          <Link to={`/useredit/${item?.id}`} onClick={() => { localStorage.setItem('editUsers', JSON.stringify(item)) }}><i class="fa fa-pencil" aria-hidden="true"></i></Link>
                          <Link onClick={() => { deleteBtn(item?.id) }}><i class="fa fa-trash" aria-hidden="true"></i></Link>

                        </div>
                      </td>
                    </tr>
                  })
                  }
                </tbody>
              </table>
              <div className='nodata-found-div'>
                {
                  userList?.length == 0 ?
                    <p>No Data Found</p>
                    //  <img 
                    //  className='table-image'
                    //  src={require('../../assets/OIP.png')}/>
                    : null
                }
              </div>
            </div>
            <div class="tablepgntn">
              <p>Show <select value={getLimit} onChange={(val) => { 
                setLimit(val.target.value);
                setCurrentPage(1)
                }}>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={40}>40</option>
                <option value={50}>50</option>
              </select> Entries</p>
              <ul>
                {/* {currentItems.map(item => (
          <li key={item}>{item}</li>
        ))} */}
              </ul>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
              {/* <ul>
               
                <li class="active">
                  <Link onClick={() => { setoffsetValue(0) }}>01</Link>

                </li>
                <li>
                  <Link onClick={() => { setoffsetValue(10) }}>02</Link>

                </li>
                <li>
                  <Link onClick={() => { setoffsetValue(20) }}>03</Link>

                </li>
                <li>
                  <Link onClick={() => { setoffsetValue(30) }}>04</Link>

                </li>
               
              </ul> */}
            </div>
          </div> :
            <Loding />

          }

        </div>
      </div>
      <div class="modalpop" id="modalpop">
        <div class="modalsbody">
          <h3>Do You Want to Delete ?</h3>
          <div class="popBtn">
            <button type="button" class="secussBtn" onClick={yesDelete}>
              Yes
            </button>
            <button type="button" class="cancelBtn" onClick={closeBtn}>
              No
            </button>
          </div>
        </div>
      </div>
    </div>

  )
}
export default User;