
import httpClient from '../../HTTPService';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Loding from '../../Component/Loding/Loding';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
const Notification = () => {
    const [offsetValue, setoffsetValue] = useState(0);
    const navigate = useNavigate();
    const [deleteyes, setdeleteyes] = useState(null)
    const [notificationList, setNotificationList] = useState([]);
    let textInput = React.createRef();
    const [getLimit, setLimit] = useState(10);
    const [searchtext, setsearchtext] = useState('')
    const [totalCount, setTotalCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const items = Array.from({ length: totalCount }, (_, index) => `Item ${index + 1}`);
    const itemsPerPage = 10;
    const totalPages = Math.ceil(items.length / getLimit);
    const startPage = Math.max(1, currentPage - Math.floor(5 / 2));
    const endPage = Math.min(totalPages, currentPage + Math.floor(5 / 2));
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    const startIndex = (currentPage - 1) * getLimit;
    const currentItems = items.slice(startIndex, startIndex + getLimit);
    // pagination
    const Pagination = ({ currentPage, totalPages, onPageChange }) => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(i);
        }
        console.log(currentPage, 'current pages...')
        return (
            <div className="pagination">
                {currentPage > 1 && (
                    <button className="pagination-btn" onClick={() => onPageChange(currentPage - 1)}>
                        <i className="fa fa-chevron-left" aria-hidden="true"></i>
                    </button>
                )}
                {startPage > 1 && (
                    <button className="pagination-btn" onClick={() => onPageChange(1)}>
                        1
                    </button>
                )}
                {startPage > 2 && <span className="pagination-ellipsis">...</span>}
                {pages.slice(startPage - 1, endPage).map(page => (
                    <button
                        key={page}
                        className={`pagination-btn ${page === currentPage ? 'active' : ''}`}
                        onClick={() => onPageChange(page)}
                    >
                        {page}
                    </button>
                ))}
                {endPage < totalPages - 1 && <span className="pagination-ellipsis">...</span>}
                {endPage < totalPages && (
                    <button className="pagination-btn" onClick={() => onPageChange(totalPages)}>
                        {totalPages}
                    </button>
                )}
                {currentPage < totalPages && (
                    <button className="pagination-btn" onClick={() => onPageChange(currentPage + 1)}>
                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </button>
                )}
            </div>
        );
    };


    useEffect(() => {
        getSub(searchtext, currentPage, getLimit);
    }, [currentPage, getLimit])


    // search Subcription
    const searchtxt = () => {
        getSub(searchtext, 1, getLimit);
    }
    // get Subcription

    const getSub = async (search_name, pagecount, limit) => {
        try {
            const result = await httpClient.post("/admin/notifications-list", { search_name, offset: pagecount, limit: limit }, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            if (result.data.status && result.data.data.length !== 0) {
                // toast.success("Subscription featch Successfully!")
                setNotificationList(result.data.data);
                setTotalCount(result?.data.total_count)
            }
            if (result.data.data.length === 0) {
                toast.error("No Subscription Found")
                setNotificationList(result.data.data);
            }
        } catch (error) {
            toast.error(error.message)
        }


    }

    const deleteBtn = (id) => {
        document.getElementById('modalpop').classList.add('open')
        setdeleteyes(id)
    }
    const closeBtn = () => {
        document.getElementById('modalpop').classList.remove('open')
    }
    return (
        <div className='subscription'>
            <div class="row">
                <div class="col-md-12">
                    <div class="search-box">
                        <div class="searchtct">

                            <i class="fa fa-search" aria-hidden="true"></i>
                            <input
                                type="text"
                                name="text"
                                placeholder="Search..."
                                onChange={(val) => setsearchtext(val.target.value)}
                                onKeyDown={() => {
                                    if (searchtext === '') {
                                        getSub(searchtext, currentPage, getLimit)
                                    }
                                }}
                            />
                            <button type='button' className='searchBtn' onClick={searchtxt}>Search</button>
                            <button type='button' className='searchBtn'><Link to="/createnotification" >Add</Link></button>

                        </div>

                    </div>
                    {notificationList ? <div>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>SL NO</th>
                                        <th>Heading</th>

                                        <th>Title</th>
                                        <th>Description</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        notificationList.map((item, index) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        {item.image}
                                                        {/* <img
                                                            className='notification_image'
                                                            src={item.image}
                                                        /> */}
                                                    </td>

                                                    <td>{item.title}</td>
                                                    <td>{item.description}</td>
                                                    <td>{moment(item.date).format('DD-MM-YYYY')}</td>
                                                </tr>
                                            )
                                        })

                                    }

                                </tbody>
                            </table>
                            <div className='nodata-found-div'>
                                {
                                    notificationList?.length == 0 ?
                                        <p>No Data Found</p>
                                        //  <img 
                                        //  className='table-image'
                                        //  src={require('../../assets/OIP.png')}/>
                                        : null
                                }
                            </div>
                        </div>
                        <div class="tablepgntn">
                            <p>Show <select value={getLimit} onChange={(val) => {
                                setLimit(val.target.value);
                                setCurrentPage(1)
                            }}>
                                <option>10</option>
                                <option>20</option>
                                <option>30</option>
                                <option>40</option>
                                <option>50</option>
                            </select> Entries</p>
                            <ul>

                            </ul>
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div> :
                        <Loding />

                    }

                </div>
            </div>

        </div>

    )
}
export default Notification;