import './Member.css';
import { useMemo, useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import httpClient from '../../HTTPService';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
const Member = () => {
    const [offsetValue, setoffsetValue] = useState(0);
    const [memberlist, setmemberlist] = useState([]);
    const [originalMemberList, setOriginalMemberList] = useState([])
    const navigate = useNavigate();
    const [searchtext, setsearchtext] = useState('')
    const [totalCount, setTotalCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const [getLimit, setLimit] = useState(10);
    const items = Array.from({ length: totalCount }, (_, index) => `Item ${index + 1}`);
    const itemsPerPage = 10;
    const totalPages = Math.ceil(items.length / getLimit);
    const startPage = Math.max(1, currentPage - Math.floor(5 / 2));
    const endPage = Math.min(totalPages, currentPage + Math.floor(5 / 2));
    const [filter, setfilter] = useState(3)
    let location = useLocation()
    const [setChange, setLimitChange] = useState(false)
    const handlePageChange = (page) => {
        setCurrentPage(page);
        // location  = undefined
    };
    const startIndex = (currentPage - 1) * getLimit;
    const currentItems = items.slice(startIndex, startIndex + getLimit);

    //pagination
    const Pagination = ({ currentPage, totalPages, onPageChange }) => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(i);
        }
        console.log(currentPage, 'current pages...')
        return (
            <div className="pagination">
                {currentPage > 1 && (
                    <button className="pagination-btn" onClick={() => onPageChange(currentPage - 1)}>
                        <i className="fa fa-chevron-left" aria-hidden="true"></i>
                    </button>
                )}
                {startPage > 1 && (
                    <button className="pagination-btn" onClick={() => onPageChange(1)}>
                        1
                    </button>
                )}
                {startPage > 2 && <span className="pagination-ellipsis">...</span>}
                {pages.slice(startPage - 1, endPage).map(page => (
                    <button
                        key={page}
                        className={`pagination-btn ${page === currentPage ? 'active' : ''}`}
                        onClick={() => onPageChange(page)}
                    >
                        {page}
                    </button>
                ))}
                {endPage < totalPages - 1 && <span className="pagination-ellipsis">...</span>}
                {endPage < totalPages && (
                    <button className="pagination-btn" onClick={() => onPageChange(totalPages)}>
                        {totalPages}
                    </button>
                )}
                {currentPage < totalPages && (
                    <button className="pagination-btn" onClick={() => onPageChange(currentPage + 1)}>
                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </button>
                )}
            </div>
        );
    };

    useEffect(() => {
        if (location?.state?.backpagi == undefined) {
            getMembers(searchtext, currentPage, getLimit);
        } else if (location?.state?.backpagi != undefined) {
            //    console.log('fkjsdfjskfjskfjsklfjskf')
            setCurrentPage(location?.state?.backpagi?.pageCurrent)
            setLimit(location?.state?.backpagi?.limit)
            getMembers(searchtext, location?.state?.backpagi?.pageCurrent, location?.state?.backpagi?.limit)
        }

    }, [currentPage, getLimit]);
    const editItem = (e) => {
        // console.log(e);
        let paginationState = {
            pageCurrent: currentPage,
            limit: getLimit,

        }
        navigate(`/memberprofile-details?id=${e?.id}`, {
            state: {
                paginationState
            }
        });
        //  localStorage.setItem('editSubData',editSub)
    }
    const viewIncome = (e) => {
        let paginationState = {
            pageCurrent: currentPage,
            limit: getLimit,

        }
        navigate(`/memberincome?id=${e?.id}`,
            {
                state: {
                    paginationState
                }
            }

        );
        //  localStorage.setItem('editSubData',editSub)
    }
    const viewTeam = (e) => {
        console.log(e);
        let paginationState = {
            pageCurrent: currentPage,
            limit: getLimit,

        }
        // const editSub = JSON.stringify(e)
        navigate(`/member-teamdetails?id=${e?.id}`,

            {
                state: {
                    paginationState
                }
            }
        );
        //  localStorage.setItem('editSubData',editSub)
    }
    const searchtxt = () => {
        getMembers(searchtext, 1, getLimit);
    }

    const getMembers = async (search_name, pagecount, limit) => {
        try {
            const result = await httpClient.post('/admin/members-list', {
                search_name, offset: pagecount, limit: limit
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })

            if (result.status === 200) {
                // location?.state = ''
                // console.log(result.data.data, 'data..')
                location.state = null
                setTotalCount(result?.data.total_count)
                setOriginalMemberList(result?.data?.data)
                setChange(false)
            }

        } catch (error) {
            //   toast.error(error.message)
        }
    }

    // filterd data
    useMemo(() => {
        console.log(filter, 'filterd...')


        if (filter != 3) {
            let _filterdata = [...originalMemberList].filter(_fitem => _fitem.active == filter)
            console.log(_filterdata, filter, 'filted data..')
            setmemberlist(_filterdata)
        } else if (filter == 3) {
            console.log('set orginal')
            setmemberlist(originalMemberList)
        }


        return () => { }

    }, [filter, originalMemberList])
    return (
        <div className="member">

            <div className=''>

                <div class="search-box">
                    <div class="searchtct ">

                        <i class="fa fa-filter" aria-hidden="true"></i>
                        <select
                            value={filter}
                            // className='ml-3'
                            // disabled={chooseFldType === '' ? true : false}
                            onChange={(val) => { setfilter(val.target.value) }}
                        //   className={(isrequired && labelvalue === null) ? 'required' :'' }
                        >
                            <option value={3} >All</option>
                            <option value={1}>Active</option>
                            <option value={0}>Inactive</option>
                        </select>

                    </div>
                    <div class="searchtct">

                        <i class="fa fa-search" aria-hidden="true"></i>
                        <input type="text" name="text" placeholder="Search..."
                            onChange={(val) => setsearchtext(val.target.value)}
                            onKeyDown={() => {
                                if (searchtext === '') {
                                    getMembers(searchtext, currentPage, getLimit)
                                }
                            }}
                        />
                        <button type='button' className='searchBtn' onClick={searchtxt} >Search</button>


                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>SL NO</th>
                                    <th> Member ID</th>
                                    <th>Name</th>
                                    {/* <th>Validity</th>
                <th >Description</th> */}
                                    {/* <th>Discount</th>
                <th>Price</th> */}
                                    <th>Refered ID</th>
                                    <th>Mobile</th>
                                    <th>Address</th>
                                    <th>Joing Date</th>
                                    <th>Member Status</th>
                                    <th>Position</th>
                                    <th>Income</th>
                                    <th>Profile</th>
                                    <th>Team</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    memberlist?.map((item, index) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.member_code}</td>
                                                    <td>{item?.name}</td>
                                                    <td>{item?.referred_by}</td>
                                                    <td>{item?.phone}</td>
                                                    <td>{item?.address !== null ? item?.address : '--'}</td>
                                                    <td>{moment(item?.joing_date).format('DD-MM-YYYY')}</td>
                                                    <td>
                                                        <span className={item?.active == 1 ? "active" : "inactive"}>{item?.active == 1 ? "Active" : "Inactive"}</span>
                                                        <p className='member-expiridata'>{
                                                            item?.active !== 0 ?
                                                                moment(item?.membership_expiry_date).format('DD-MM-YYYY')
                                                                : null
                                                        }</p>
                                                    </td>
                                                    <td>{item?.position}</td>
                                                    <td >
                                                        <div className='table-column'>
                                                            <i
                                                                onClick={() => viewIncome(item)}
                                                                class="fa fa-money team-incom" aria-hidden="true"></i>
                                                        </div>


                                                    </td>
                                                    <td>
                                                        <div className='table-column'>
                                                            <i
                                                                onClick={() => editItem(item)}
                                                                class="fa fa-user team-incom" aria-hidden="true"></i>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='table-column'>
                                                            <i
                                                                onClick={() => viewTeam(item)}

                                                                class="fa fa-group team-incom" aria-hidden="true"></i>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    })
                                }

                            </tbody>

                        </table>
                        <div className='nodata-found-div'>
                            {
                                memberlist?.length == 0 ?
                                    <p>No Data Found</p>
                                    //  <img 
                                    //  className='table-image'
                                    //  src={require('../../assets/OIP.png')}/>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div class="tablepgntn">
                <p>Show <select value={getLimit} onChange={(val) => {
                    setLimit(val.target.value)
                    setCurrentPage(1)
                    // setLimitChange(true)
                }}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={40}>40</option>
                    <option value={50}>50</option>
                </select> Entries</p>
                <ul>

                </ul>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />

            </div>

        </div>
    )
}
export default Member;