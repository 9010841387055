import { useEffect, useState } from "react";
import httpClient from "../HTTPService";
import moment from "moment";

function Reports() {
    const [rportsList, setReportslist] = useState([])
    const [searchtext, setsearchtext] = useState('')
    const [totalCount, setTotalCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const [getLimit, setLimit] = useState(10);
    const items = Array.from({ length: totalCount }, (_, index) => `Item ${index + 1}`);
    const itemsPerPage = 10;
    const totalPages = Math.ceil(items.length / getLimit);
    const startPage = Math.max(1, currentPage - Math.floor(5 / 2));
    const endPage = Math.min(totalPages, currentPage + Math.floor(5 / 2));
    const [weeklyData, setweeklyData] = useState([])
    useEffect(() => {
        getreports(searchtext, currentPage, getLimit);
    }, [currentPage, getLimit]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    //search button

    const searchtxt = () => {
        getreports(searchtext, 1, getLimit);
    }
    //pagiantion

    const Pagination = ({ currentPage, totalPages, onPageChange }) => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(i);
        }
        console.log(currentPage, 'current pages...')
        return (
            <div className="pagination">
                {currentPage > 1 && (
                    <button className="pagination-btn" onClick={() => onPageChange(currentPage - 1)}>
                        <i className="fa fa-chevron-left" aria-hidden="true"></i>
                    </button>
                )}
                {startPage > 1 && (
                    <button className="pagination-btn" onClick={() => onPageChange(1)}>
                        1
                    </button>
                )}
                {startPage > 2 && <span className="pagination-ellipsis">...</span>}
                {pages.slice(startPage - 1, endPage).map(page => (
                    <button
                        key={page}
                        className={`pagination-btn ${page === currentPage ? 'active' : ''}`}
                        onClick={() => onPageChange(page)}
                    >
                        {page}
                    </button>
                ))}
                {endPage < totalPages - 1 && <span className="pagination-ellipsis">...</span>}
                {endPage < totalPages && (
                    <button className="pagination-btn" onClick={() => onPageChange(totalPages)}>
                        {totalPages}
                    </button>
                )}
                {currentPage < totalPages && (
                    <button className="pagination-btn" onClick={() => onPageChange(currentPage + 1)}>
                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </button>
                )}
            </div>
        );
    };
    // get reports
    const getreports = async (search_name, pagecount, limit) => {
        try {
            const result = await httpClient.post('/admin/reports-list', {
                search_name, offset: pagecount, limit: limit
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })

            if (result.status === 200) {
                let week_arr = []
                console.log(result.data.data, 'data..')
                setTotalCount(result?.data.total_count)
                setReportslist(result?.data?.data)
                const weeklyArrayWithKeys = Object.entries(result?.data?.weekly_report_data).map(([key, value]) => ({
                    key,
                    ...value
                }));
               setweeklyData(weeklyArrayWithKeys)
                // setweeklyData(result?.data?.weekly_report_data)
            }

        } catch (error) {
            //   toast.error(error.message)
        }
    }
    return (
        <>
            <div className="">

                <div class="search-box">
                    <div class="searchtct">

                        <i class="fa fa-search" aria-hidden="true"></i>
                        <input type="text" name="text" placeholder="Search..."
                            onChange={(val) => setsearchtext(val.target.value)}
                            onKeyDown={() => {
                                if (searchtext === '') {
                                    getreports(searchtext, currentPage, getLimit)
                                }
                            }}
                        />
                        <button type='button' className='searchBtn' onClick={searchtxt} >Search</button>


                    </div>

                </div>
                <div class="row weekly-padinig-box">
                    {
                        weeklyData?.map((item) => {
                            return (

                                <div class="col-md-3 weekly-box">
                                    <div>
                                        <u className="price-profit">{item?.key}</u>
                                        <p className="price-profit">{item?.date}</p>
                                        <p className="price-profit"> 
                                        <i className="fa fa-inr ml-2" />
                                            {item?.total_price}</p>
                                        </div>
                                </div>

                            )
                        })
                    }
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>SL NO</th>
                                        <th>User Name</th>
                                        <th>Date</th>
                                        <th>Subscription Name</th>
                                        <th>Price (<i className="fa fa-inr" />)</th>
                                        <th>Profit (<i className="fa fa-inr" />)</th>
                                        <th>Level 1 Commission(D1)</th>
                                        <th>Level 2 Commission(D2)</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        rportsList?.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.user_name}</td>
                                                    <td>{moment(item?.date).format('DD-MM-YYYY')}</td>
                                                    <td>{item?.subscription_name}</td>
                                                    <td>{item?.price}</td>
                                                    <td>{item?.profit}</td>
                                                    <td>{item?.level_1_commission}</td>
                                                    <td>{item?.level_2_commission}</td>

                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            <div className='nodata-found-div'>
                                {
                                    rportsList?.length == 0 ?
                                        <p>No Data Found</p>
                                        //  <img 
                                        //  className='table-image'
                                        //  src={require('../../assets/OIP.png')}/>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tablepgntn">
                    <p>Show <select value={getLimit} onChange={(val) => {
                        setLimit(val.target.value);
                        setCurrentPage(1)
                    }}>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                        <option value={40}>40</option>
                        <option value={50}>50</option>
                    </select> Entries</p>
                    <ul>

                    </ul>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />

                </div>

            </div>

        </>
    )
}
export default Reports