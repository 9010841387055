import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import './Layout.css';
import Header from "./Header/Header";
import Menu from "./Menu/Menu";
export const LayoutContext = React.createContext()

const Layout = () => {
  const [tooglemenu, settooglemenu] = useState(false);

  const [inactiveTime, setInactiveTime] = useState(0);
  const timerRef = useRef(null);
  let navigate = useNavigate()

  // check user inactive or not
  useEffect(() => {
    const handleActivity = () => {
      setInactiveTime(0); // Reset timer on user activity
      resetTimer()
    };

    const startTimer = () => {
      timerRef.current = setInterval(() => {
        setInactiveTime((prevTime) => prevTime + 1);
        console.log('loggeddd..')
      }, 60000); // Increment every minute
    };

    const resetTimer = () => {
      clearInterval(timerRef.current);
      startTimer();
    };

    // Start timer initially
    startTimer();

    // Set up event listeners for user activity
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('click', handleActivity);

    return () => {
      clearInterval(timerRef.current);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('click', handleActivity);
    };
  }, []);

  useMemo(() => {
    if (inactiveTime >= 30) {
      navigate('/')
    }
  }, [inactiveTime])

  useEffect(() => {
    console.log(tooglemenu, 'toogle menu')
  }, [tooglemenu])
  return (
    <LayoutContext.Provider value={{ tooglemenu, settooglemenu }}>
      <div className="layout" id="mainWapper">
        <div className={`menu ${tooglemenu ? 'active-menu' : ''}`}>
          <Menu />
        </div>
        <div className="wapper">
          <div className={`${tooglemenu ? 'header_active' : 'header'} `}>
            <Header />
          </div>
          <div className={`${tooglemenu ? 'wapperbody_menuactive' : 'wapperbody'}  `}>
            <Outlet />
          </div>
        </div>
      </div>
    </LayoutContext.Provider>

  )
}
export default Layout;