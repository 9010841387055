import './Help.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-accessible-accordion/dist/fancy-example.css';
import React, { useMemo, useState } from 'react';
import { useEffect } from 'react';
import httpClient from '../../HTTPService';
import Loding from '../../Component/Loding/Loding';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const Help = () => {
  const navigate = useNavigate();
  const [offsetValue, setoffsetValue] = useState(0);
  let textInput = React.createRef();
  const [getLimit, setLimit] = useState(10);
  const [helplist, setGetHelpLists] = useState([])
  const [originalhelplist, setOriginalHelpLists] = useState([])
  const [searchtext, setsearchtext] = useState('')
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1);
  const items = Array.from({ length: totalCount }, (_, index) => `Item ${index + 1}`);
  const totalPages = Math.ceil(items.length / getLimit);
  const startPage = Math.max(1, currentPage - Math.floor(5 / 2));
  const endPage = Math.min(totalPages, currentPage + Math.floor(5 / 2));
  const [filter, setfilter] = useState(3)
  const [isfetching, setIsFeatching] = useState(false)
  const location = useLocation()
  const location_data = location?.state?.name
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // useEffect(() => {
  //   if (location?.state?.name != undefined) {
  //     setfilter(location?.state?.name)
  //     console.log(parseInt(location?.state?.name))
  //   }
  // }, [location])
  //paginaton
  const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    console.log(currentPage, 'current pages...')
    return (
      <div className="pagination">
        {currentPage > 1 && (
          <button className="pagination-btn" onClick={() => onPageChange(currentPage - 1)}>
            <i className="fa fa-chevron-left" aria-hidden="true"></i>
          </button>
        )}
        {startPage > 1 && (
          <button className="pagination-btn" onClick={() => onPageChange(1)}>
            1
          </button>
        )}
        {startPage > 2 && <span className="pagination-ellipsis">...</span>}
        {pages.slice(startPage - 1, endPage).map(page => (
          <button
            key={page}
            className={`pagination-btn ${page === currentPage ? 'active' : ''}`}
            onClick={() => onPageChange(page)}
          >
            {page}
          </button>
        ))}
        {endPage < totalPages - 1 && <span className="pagination-ellipsis">...</span>}
        {endPage < totalPages && (
          <button className="pagination-btn" onClick={() => onPageChange(totalPages)}>
            {totalPages}
          </button>
        )}
        {currentPage < totalPages && (
          <button className="pagination-btn" onClick={() => onPageChange(currentPage + 1)}>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </button>
        )}
      </div>
    );
  };
  // get help list
  const getHelpList = async (search_name, pagecount, limit) => {
    try {
      const result = await httpClient.post("/admin/helps-list", {
        search_name, search_status:
          location_data != undefined ? location_data
            : filter == 3 ? null : filter, offset: pagecount, limit: limit
      }, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      if (result.data.status == true) {
        setGetHelpLists(result.data.data)
        setTotalCount(result?.data.total_count)
        location.state = null
        // setIsFeatching(true)
        // toast.success(result.data.message +" "+"featch Successfully!")
      }

    }

    catch (error) {
      // toast.error(error.message)
    }
  }
  useMemo(() => {
    getHelpList(searchtext, currentPage, getLimit);
  }, [currentPage, getLimit]);
  // search
  // search Subcription
  const searchtxt = () => {
    getHelpList(searchtext, currentPage, getLimit);
  }
  // replay
  const msgReplay = (data) => {
    console.log("ok");
    const msg = JSON.stringify(data)
    localStorage.setItem('rply', msg)
    navigate('/messagereplay')
  }

  useMemo(() => {
    console.log(filter, 'filterd...')
    getHelpList()

    return () => { }

  }, [filter])
  return (
    <div className="help">
      <ToastContainer 
      autoClose={200}
      />
      {helplist ?
        <div>
          <div className='helpBox'>

            <div class="search-box">
              <div class="searchtct ">

                <i class="fa fa-filter" aria-hidden="true"></i>
                <select
                  value={filter}
                  // className='ml-3'
                  // disabled={chooseFldType === '' ? true : false}
                  onChange={(val) => { setfilter(val.target.value) }}
                //   className={(isrequired && labelvalue === null) ? 'required' :'' }
                >
                  <option value={3} >All</option>
                  <option value={0}>Pending</option>
                  <option value={1}>Success</option>
                </select>

              </div>
              <div class="searchtct">

                <i class="fa fa-search" aria-hidden="true"></i>
                <input type="text"
                  name="text" placeholder="Search..."
                  onChange={(val) => setsearchtext(val.target.value)}
                  onKeyDown={() => {
                    if (searchtext === '') {
                      getHelpList(searchtext, currentPage, getLimit)
                    }
                  }}

                />
                <button type='button' className='searchBtn' onClick={searchtxt}>Search</button>
              </div>

            </div>

            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>SL NO</th>
                    <th>User Name</th>
                    <th>Phone Number</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>

                  {
                    helplist?.map((item, index) => {
                      return <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.phone}</td>
                        <td>{item.date}</td>
                        <td><span className={item?.status == 1 ? "active" : "inactive"}>{item?.status == 1 ? "Susess" : "Pending"}</span></td>
                        <td><Link onClick={() => { msgReplay(item) }} className='active'>Replay</Link></td>
                      </tr>
                    })
                  }
                </tbody>
              </table>
              <div className='nodata-found-div'>
                {
                  helplist?.length == 0 ?
                    <p>No Data Found</p>
                    //  <img 
                    //  className='table-image'
                    //  src={require('../../assets/OIP.png')}/>
                    : null
                }
              </div>
            </div>

          </div>
          <div class="tablepgntn">
            <p>Show <select value={getLimit} onChange={(val) => { 
              setLimit(val.target.value);
              setCurrentPage(1)
              }}>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={40}>40</option>
              <option value={50}>50</option>
            </select> Entries</p>
            <ul>

            </ul>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />

          </div>
        </div>
        : <Loding />}


    </div>
  )
}
export default Help;