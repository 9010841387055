import actionType from "../Action/ActionType"
const initialState = {
    numberofBook : 20
}

const bookReducer = (state = initialState, {type, payload})=>{
       switch (type) {
        case actionType.buy_book : return{
         
            
             ...state, numberofBook:state.numberofBook -payload
        }
        
       
        default: return state
           
       }
}
export default bookReducer;