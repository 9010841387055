import { ToastContainer, toast } from "react-toastify";
import noImage from '../../assets/noimage.jpg';
import { useState } from "react";
import httpClient from "../../HTTPService";
import { useNavigate } from "react-router-dom";
function NotificationCreate() {
    const [imagePrvw, setimageprew] = useState('')
    const [title, settitle] = useState('')
    const [description, setdescription] = useState('')

    const [heading, setheaading] = useState('')
    const navigate = useNavigate()

    //create notification
    async function createNotification() {
        let _data = {
            title: title,
            image: heading,
            description: description
        }
        const formData = new FormData();
        formData.append('title', title);
        formData.append('image',heading );
        formData.append('description', description);
        console.log(_data)
        const result = await httpClient.post("/admin/notifications/create", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "multipart/form-data",
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })

        if (result.status === 200) {

            toast.success(result.data.message);
            setTimeout(() => {
                navigate('/notificationlist')
            }, 1000)

        }


        if (result.data.status == false) {
            toast.error(result.data.message)
        }


    }

    // const uploadimage = (e) => {
    //     console.log(e.target.files[0])
    //     setcatImagefile(e.target.files[0]);
    //     // console.log(e.target.files, 'mfiddfs')
    //     // console.lo
    //     setimageprew(URL.createObjectURL(e.target.files[0]))
    // }


    return (
        <>
            <div className="cataddedit">
                <ToastContainer
                    autoClose={1000}
                />

                <div className="card">
                    <form id="addForm">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="formGroup">
                                    <label>Notification Title</label>
                                    <input type="text"
                                        // id="subscriptionName"
                                        // name="subscriptionName"
                                        placeholder="Notification Title"
                                        onChange={(val) => settitle(val.target.value)}
                                        value={title} required />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="formGroup">
                                    <label>Notification Description</label>
                                    <input type="text"
                                        // id="subscriptionName"
                                        // name="subscriptionName"
                                        placeholder="Notification Description"
                                        onChange={(val) => setdescription(val.target.value)}
                                        value={description} required />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="formGroup">
                                    <label>Notification Heading</label>
                                    <input type="text"
                                        // id="subscriptionName"
                                        // name="subscriptionName"
                                        placeholder="Notification Heading"
                                        onChange={(val) => setheaading(val.target.value)}
                                        value={heading} required />
                                </div>
                            </div>
                        </div>

                        {/* <div className="row">
                            <div className="col-md-3">

                                <div className="formGroup flxfrm">
                                    <div className="catIcon">
                                        <img src={imagePrvw ? imagePrvw : noImage}
                                        />
                                    </div>
                                    <span className="uploadImage"
                                        onChange={(e) => { uploadimage(e) }}
                                    >
                                        <input type="file" name="image" />
                                        <p className="uploade-text">Upload Image</p>
                                        <div className="upload-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708z" />
                                                <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383m.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                                            </svg>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div> */}
                    </form>
                    <div className="col-md-12">
                        <div className="btngrp">
                            <button type="button" onClick={() => navigate('/notificationlist')} className="themeBtn cancelBtn" >Cancel</button>
                            <button type="button" onClick={() => createNotification()} className="themeBtn" >Submit</button>
                        </div>

                    </div>
                </div>
            </div>


        </>
    )
}

export default NotificationCreate