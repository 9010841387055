
import { useLocation, useNavigate } from "react-router-dom";
import React, { useMemo, useRef, useState } from "react";
import httpClient from "../../HTTPService";
// import { toast } from 'react-toastify';
import { useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';

const AddEditSubscription = () => {
    const navigate = useNavigate();
    const [subscriptionName, setSubscriptionName] = useState();
    const [timePeriod, setTimePeriod] = useState();
    const [subDescription, setSubDescription] = useState();
    const [subPrice, setSubPrice] = useState('0');
    const [subDiscount, setSubDiscount] = useState(0);
    const [subTag, setSubTab] = useState();
    const [subStatus, setSubStatus] = useState();
    const [subId, setSubId] = useState();
    const [editMode, seteditMode] = useState(false)
    const [filnaldata, setfinaldata] = useState([])
    const [planename, setplanname] = useState('')
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search); // Parse query string
    const id = queryParams.get('id');
    const prevCountRef = useRef();
    const [status, setStatus] = useState('')
    const [validitySelectedArray, setValiditySelectedArray] = useState([])
    const [deletedEdititem, setDeletedEdititem] = useState([])

    // useEffect(() => {
    //     // Update the ref with the current count value after the render
    //     prevCountRef.current = subDiscount;
    //     console.log(prevCountRef)
    // }, [subDiscount]);

    const validityType = [
        {
            name: '1 Months',
            value: 1
        },
        {
            name: '3 Months',
            value: 3
        },
        {
            name: '6 Months',
            value: 6
        },
        {
            name: '12 Months',
            value: 12
        }
    ]
    useEffect(() => {
        if (id != undefined) {
            // setloadinigstate(true)
            httpClient.post("/admin/subscriptions/view", {
                "id": id
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then((res) => {
                console.log(res.data.data, 'kkj')
                setSubscriptionName(res.data.data.name)
                let _actual_data = res.data.data.subscriptions?.map((item) => {
                    let _data = {
                        id: item?.id,
                        name: item?.name,
                        deleted: item?.deleted,
                        type: item?.type,
                        description: item?.description,
                        is_subscription: item?.is_subscription,
                        active: item?.active,
                        discount: item?.discount,
                        mrp: item?.mrp
                    }
                    return _data
                })
                let _validityArray = _actual_data?.map((item) => {
                    return item?.type
                    // setValiditySelectedArray(_data)
                    // validitySelectedArray(item?.type)
                })
                // console.log(_validityArray)
                setValiditySelectedArray(_validityArray)
                setfinaldata(_actual_data)
                // setDeletedEdititem(_actual_data)

            })

        }

    }, [id])
    // const prevCount = prevCountRef.current;

    //  check validity period array
    useMemo(() => {
        let _validityPeriod = filnaldata?.map((i) => {
            return i?.type
        })
        console.log(_validityPeriod)
        setValiditySelectedArray(_validityPeriod)
    }, [filnaldata])
    function checkInputValidate(event) {
        let { value, min, max } = event.target;
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        const newValue = event.target.value;
        let _data = subPrice - (subDiscount * subPrice / 100)
        console.log(_data)
        // Convert input to a number
        const numericValue = Number(newValue);
        const regex = /^[0-9]*$/;
        // Check if the numeric value is less than or equal to 100
        if (regex.test(newValue)) {
            console.log('regex loggedd')
            if (numericValue <= 100 || newValue === '') {
                setSubDiscount(newValue);
            }
            
        }

    }

    function ondeleteItems(indexs, id) {
        if (id !== null) {
            
            setDeletedEdititem([...deletedEdititem,id?.toString()])
            let _modifiedarray = filnaldata?.filter((obj, index) => index !== indexs)
            setfinaldata(_modifiedarray)
        } else {
            let _modifiedarray = filnaldata?.filter((obj, index) => index !== indexs)
            setfinaldata(_modifiedarray)
        }
    }
    const addednewItem = () => {
        // console.log(
        //     {
        //         name: planename,
        //         type: timePeriod,
        //         description: subDescription,
        //         active: subStatus,
        //         discount: subDiscount,
        //         mrp: subPrice
        //     }, 'data view.....'
        // )
        setfinaldata(prevOptionData => {
            
            if (prevOptionData.length > 0) {
                // validitySelectedArray?.push(timePeriod)
                return [...prevOptionData, {
                    id: null,
                    name: planename,
                    type: timePeriod,
                    description: subDescription,
                    active: subStatus,
                    discount: subDiscount,
                    mrp: subPrice
                }];

            } else {
               
                // validitySelectedArray?.push(timePeriod)
                return [{
                    id: null,
                    name: planename,
                    type: timePeriod,
                    description: subDescription,
                    active: subStatus,
                    discount: subDiscount,
                    mrp: subPrice
                }];
            }
        });
    }

    const addCatform = async () => {
        // event.preventDefault();
        console.log(deletedEdititem)
        if (id != undefined) {
            // const formData = new FormData();
            let _data = {
                id: id,
                name: subscriptionName,
                subscription_data: filnaldata,
                subscription_deleted_ids:deletedEdititem

            }
            try {
                const result = await httpClient.post("/admin/subscriptions/update", _data, {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                })
                if (result.data.status) {
                    // localStorage.removeItem('editSubData')
                    document.getElementById('addForm').reset();
                    toast.success(result.data.message);
                    setTimeout(() => {
                        navigate('/subscription')
                    }, 1000)

                }


                if (result.data.status == false) {
                    toast.error(result.data.message)
                }


            }
            catch (error) {
                toast.error(error.message)
            }
        }
        else {
            const formData = new FormData();
            formData.append('name', subscriptionName);
            formData.append('subscription_data', filnaldata);
            let data = {
                name: subscriptionName,
                subscription_data: filnaldata

            }

            console.log(Object.fromEntries(formData))
            try {
                const result = await httpClient.post("/admin/subscriptions/create", data, {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }

                });

                document.getElementById('addForm').reset();
                toast.success(result.data.message)
                setTimeout(() => {
                    navigate('/subscription')
                }, 1000)
            }
            catch (error) {
                toast.error(error.message)

            }
        }
    }

    // items name changes
    const nameFieldchanges = (index, data) => {
        let _modifyData = [...filnaldata]
        _modifyData[index].name = data
        setfinaldata(_modifyData)

    }

    const validityFieldChanges = (index, data) => {
        let _modifyData = [...filnaldata]
        _modifyData[index].type = data
        setfinaldata(_modifyData)
    }

    const statusFieldChanges = (index, data) => {
        let _modifyData = [...filnaldata]
        _modifyData[index].active = data
        setfinaldata(_modifyData)
    }

    const priceFieldChanges = (index, data) => {
        let _modifyData = [...filnaldata]
        _modifyData[index].mrp = data
        setfinaldata(_modifyData)
    }

    const discountPercentageChanges = (index, data) => {
        let _modifyData = [...filnaldata]
        const numericValue = Number(data);
        const regex = /^[0-9]*$/;
        if (regex.test(data)) {
            console.log('regex loggedd')
            if (numericValue <= 100 || data === '') {
                _modifyData[index].discount = data
            }
            // setValue(newValue);
        }

        setfinaldata(_modifyData)
    }

    const descriptionFieldChanges = (index, data) => {
        let _modifyData = [...filnaldata]
        _modifyData[index].description = data
        setfinaldata(_modifyData)
    }


    return (
        <div className="cataddedit">
                        <ToastContainer 
                        autoClose={1000}
                        />

            <div className="card">
                <form id="addForm">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formGroup">
                                <label>Subscription  Name</label>
                                <input type="text"
                                    // id="subscriptionName"
                                    // name="subscriptionName"
                                    placeholder="Name" onChange={(val) => setSubscriptionName(val.target.value)} value={subscriptionName} required />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formGroup">
                                <label> Plan Name</label>
                                <input type="text" name="subTag "
                                    placeholder="Enter Plan Name"
                                    onChange={(val) => setplanname(val.target.value)}
                                    value={planename} required />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="formGroup">
                                <label>Validity</label>
                                <select
                                    id=""
                                    value={timePeriod}
                                    onChange={(val) => setTimePeriod(parseInt(val?.target?.value))} >
                                    <option>Select Time Period</option>
                                    {
                                        validityType?.map((item) => {
                                            return (
                                                <>
                                                    <option
                                                        disabled={validitySelectedArray.includes(item?.value) ? true : false}
                                                        value={item?.value}>{item?.name}</option>
                                                </>
                                            )
                                        })

                                    }
                                    {/* <option disabled={} value="1">1 Months</option>
                                    <option value="3">3 Months</option>
                                    <option value="6">6 Months</option>
                                    <option value="12">12 Months</option> */}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="formGroup">
                                <label>Select Status</label>
                                <select value={subStatus} onChange={(val) => setSubStatus(val.target.value)} >
                                    <option>Select Status</option>
                                    <option value="1">Active</option>
                                    <option value="0">Inactive</option>

                                </select>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="formGroup">
                                <label>Price</label>
                                <input
                                    type="number" name="subPrice"
                                    placeholder="Enter Price"
                                    onChange={(val) => setSubPrice(val.target.value)} value={subPrice} required />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="formGroup">
                                <label>Discount (%)</label>
                                <input type="number" name="subDiscount"
                                    placeholder="Enter Discounted Percentage"
                                    onChange={(val) => checkInputValidate(val)}
                                    // maxLength={3}
                                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                    // pattern="/^[a-zA-Z0-9]+$/"
                                    max='100'
                                    min=''
                                    value={subDiscount}

                                    required />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="formGroup">
                                <label>Discounted Price</label>
                                <input type="number"
                                    name="subDiscount"
                                    placeholder=""
                                    value={subPrice - (subDiscount * subPrice / 100)}
                                    disabled
                                    required />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="formGroup">
                                <label>Description</label>
                                <textarea name="subDescription "
                                    placeholder="Enter Description"
                                    onChange={(val) => setSubDescription(val.target.value)} value={subDescription} required></textarea>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="formGroup">
                                <label>&nbsp;</label>
                                <button
                                    disabled={
                                        [1, 3, 6, 12]?.every(items =>
                                            validitySelectedArray?.some(item => item === items)
                                        ) ? true : false
                                    }
                                    type="button" onClick={() => addednewItem()} className="addBtn">Add</button>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}

                </form>
                {/* <div className="row"> */}


                {
                    filnaldata?.map((item, index) => {
                        return (
                            <div className="row">
                                <div className="col-md-3">
                                    {/* <div className="item-list"> */}
                                    <div className="formGroup">
                                        {/* <div className="item-list"> */}
                                        <div>
                                            <label>Plan Name</label>
                                            <input type="text" value={item.name}
                                                onChange={(value) => nameFieldchanges(index, value.target.value)}
                                            />
                                        </div>

                                        {/* </div> */}
                                    </div>

                                </div>
                                <div className="col-md-3">
                                    <div className="formGroup">
                                        <label>Validity</label>
                                        <select
                                            id=""
                                            // disabled
                                            value={item.type}
                                            onChange={(val) => validityFieldChanges(index, val.target.value)} >
                                            <option>Select Time Period</option>
                                            {
                                                validityType?.map((item) => {
                                                    return (
                                                        <>
                                                            <option value={item?.value}>{item?.name}</option>
                                                        </>
                                                    )
                                                })

                                            }
                                            {/* <option value="1">1 Months</option>
                                            <option value="3">3 Months</option>
                                            <option value="6">6 Months</option>
                                            <option value="12">12 Months</option> */}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="formGroup">
                                        <label>Select Status</label>
                                        <select value={item?.active}
                                            onChange={(val) => statusFieldChanges(index, val.target.value)} >
                                            <option>Select Status</option>
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>

                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="formGroup">
                                        <label>Price</label>
                                        <input type="number" name="subPrice"
                                            placeholder="Enter Price"
                                            onChange={(val) => priceFieldChanges(index, val.target.value)}
                                            value={item?.mrp} required />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="formGroup">
                                        <label>Discount (%)</label>
                                        <input type="number" name="subDiscount"
                                            placeholder="Enter Discounted Price"
                                            max='100'
                                            min=''
                                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                            onChange={(val) => discountPercentageChanges(index, val.target.value)}
                                            value={item?.discount} required />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="formGroup">
                                        <label>Discounted Price</label>
                                        <input type="number"
                                            name="subDiscount"
                                            placeholder=""
                                            value={item?.mrp - (item?.mrp * item?.discount / 100)}
                                            disabled
                                            required />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="formGroup">
                                        <label>Description</label>
                                        <textarea name="subDescription "
                                            placeholder="Enter Description"
                                            onChange={(val) => descriptionFieldChanges(index, val.target.value)}
                                            value={item?.description} required></textarea>
                                    </div>
                                </div>
                                <div>

                                    {
                                        item?.is_subscription ? null : 
                                        <div className="cross-icon" onClick={() => ondeleteItems(index, item?.id)}>
                                            <div className="">
                                                <p className="icon">X</p>

                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>


                        )
                    })
                }
                {/* </div> */}

                <div className="col-md-12">
                    <div className="btngrp">
                        <button type="button" className="themeBtn cancelBtn" onClick={() => { navigate('/subscription'); localStorage.removeItem('editSubData') }}>Cancel</button>
                        <button type="button" className="themeBtn" onClick={() => addCatform()}>{id != undefined ? "Update" : "Submit"}</button>
                    </div>

                </div>

            </div>

        </div>
    )
}
export default AddEditSubscription;