import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import httpClient from "../../HTTPService";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import './HowItsWork.css';
import moment from "moment";
const HowItsWorkDetails = () => {
    const navigate = useNavigate();
    const [workDetails, setgetWorkDetails] = useState();
    const params = useParams();
    const { id } = params
    // Edit HOW Works
    const editdata = () => {
        // console.log(e,'details')
        console.log(id, 'details is...')
        navigate(`/howitsworkaddedit?id=${14}`);
        // navigate(`/howitsworkaddedit?id=${2}`);

      
    }
    // Get HOW Works
    useEffect(() => {
        getHowWorks();
    }, [])
    const getHowWorks = async () => {
        try {
            const result = await httpClient.post("/admin/works/view", { id: id }, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (result.data.status) {

                setgetWorkDetails(result.data.data);
            }
        } catch (error) {
            // toast.error(error.message)
        }
    }
    return (
        <div className="howitsWorks">
            <ToastContainer  
            
            autoClose={1000}
            />
            <div className="card">
                <div class="row">
                    <div className="col-md-12">
                        <div className="catImagettl">
                            <div className="actioncat">
                                <Link to="" className="editcat"><i class="fa fa-pencil" aria-hidden="true" onClick={() =>  editdata()}></i></Link>
                                <Link to="/howitswork" className="backcat" ><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>
                            </div>
                            <div className="videoTag">
                                <video src={workDetails?.video_path}
                                    width={300}
                                    height={250}
                                    controls>

                                </video>
                            </div>

                            <h2>{workDetails?.title}</h2>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="catDetails">
                            <h3>Drescption</h3>
                            <p>{workDetails?.description}</p>

                            <p><label>Title :</label> {workDetails?.slug}</p>
                            <p><label>Created Date :</label> {moment(workDetails?.created_at).format('DD-MM-YYYY')}</p>
                            <p><label>Updated Date :</label> {moment(workDetails?.updated_at).format('DD-MM-YYYY')}</p>
                            <p><label>Status :</label> <span className={workDetails?.active ? "active" : "inactive"}>{workDetails?.active ? "Active" : "Inactive"}</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default HowItsWorkDetails;