
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import httpClient from "../../HTTPService";
import { ToastContainer, toast } from 'react-toastify';
import noImage from "../../assets/noimage.jpg";
import { useEffect } from "react";
import Loding from '../../Component/Loding/Loding';
const AddEditSubCategory = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const [categotyName, setCategotyName] = useState("");
    const [maincategotyName, mainCategotyName] = useState("");
    const [catStatus, setStatus] = useState("");
    const [catImage, setcatImage] = useState("");
    const [imagePrvw, setimagePrvw] = useState();
    const [itemId, setitemId] = useState('');
    const [editMode, seteditMode] = useState(false)
    const [allCat, setAllcat] = useState()
    const [loadingstate, setloadinigstate] = useState(false)
    const [amount, setAmount] = useState(0)
    // Edit Category
    const queryParams = new URLSearchParams(location.search); // Parse query string
    const id = queryParams.get('id');
    useEffect(() => {
        if (id != undefined) {
            setloadinigstate(true)
            httpClient.post("/admin/sub-categories/view", {
                "id": id
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then((res) => {
                setCategotyName(res.data.data.name)
                setimagePrvw(res.data.data.image_path)
                mainCategotyName(res.data.data.category_id)
                setAmount(res?.data?.data?.amount)
                setStatus(res?.data?.data?.active)
                setloadinigstate(false)
            })

        }
    }, [id])
    useEffect(() => {
        if (localStorage.getItem('editSubData')) {
            const currentData = JSON.parse(localStorage.getItem('editSubData'));
            setCategotyName(currentData.name)
            setimagePrvw(currentData.image_path)
            setitemId(currentData.id)
            mainCategotyName(currentData.category_id)
            seteditMode(true)
        }
        getallCat();
    }, [])

    // get all category
    const getallCat = async () => {
        try {
            const result = await httpClient.post("/admin/all-categories-list", {}, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            setAllcat(result.data.data)
        }
        catch (error) {

        }
    }
    // upload Image
    const uploadcat = (e) => {
        setcatImage(e.target.files)
        console.log(catImage);
        setimagePrvw(URL.createObjectURL(e.target.files[0]))

    }
    const addCatform = async (event) => {
        event.preventDefault();
        if (id != undefined) {
            const formData = new FormData();
            formData.append('category_id', maincategotyName);
            formData.append('id', id);
            formData.append('name', categotyName);
            formData.append('active', catStatus);
            formData.append('image', catImage[0]);
            formData.append('amount', amount);
            console.log(Object.fromEntries(formData))
            try {
                const result = await httpClient.post("/admin/sub-categories/update", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Accept": "multipart/form-data",
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                })
                if (result.data.status == true) {
                    localStorage.removeItem('editSubData')
                    setimagePrvw(null);
                    setCategotyName("")
                    document.getElementById('addForm').reset();
                    toast.success(result.data.message);
                    setTimeout(() => {
                        navigate('/subcategory')
                    }, 1000)

                }

                if (result.data.status == false) {
                    toast.error(result.data.message)
                }

            }
            catch (error) {
                toast.error(error.message)
            }
        }
        else {
            const formData = new FormData();
            formData.append('category_id', maincategotyName);
            formData.append('name', categotyName);
            formData.append('active', catStatus);
            formData.append('image', catImage[0]);
            formData.append('amount', amount);
            console.log(Object.fromEntries(formData))
            try {
                const result = await httpClient.post("/admin/sub-categories/create", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Accept": "multipart/form-data",
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }

                });

                if (result.data.status == true) {
                    mainCategotyName('')
                    setimagePrvw(null);
                    setCategotyName("")
                    document.getElementById('addForm').reset();
                    toast.success(result.data.message)
                    setTimeout(() => {
                        navigate('/subcategory')
                    }, 1000)
                }
                if (result.data.status == false) {
                    toast.error(result.data.message)
                }
            }
            catch (error) {
                toast.error(error.message)

            }
        }



    }

    return (
        <div className="cataddedit">
            <ToastContainer
                autoClose={1000}
            />
            {
                loadingstate ?
                    <Loding />
                    : null

            }
            <div className="card">
                <form onSubmit={addCatform} id="addForm">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="formGroup flxfrm">
                                <div className="catIcon">
                                    <img src={imagePrvw ? imagePrvw : noImage} />
                                </div>

                                <span className="uploadImage" onChange={(e) => { uploadcat(e) }}>

                                    <input type="file" name="image" />
                                    <p className="uploade-text">Upload Icon</p>
                                    <div className="upload-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708z" />
                                            <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383m.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                                        </svg>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="formGroup">
                                <label>Category Name</label>

                                <select value={maincategotyName} onChange={(val) => mainCategotyName(val.target.value)} required>
                                    <option>Select Category</option>
                                    {
                                        allCat?.map((item, i) => {
                                            return <option value={item.id} key={i}>{item.name}</option>
                                        })
                                    }

                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="formGroup">
                                <label>Sub Category Name</label>
                                <input type="text" name="categotyName" placeholder="Name" onChange={(val) => setCategotyName(val.target.value)} value={categotyName} required />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="formGroup">
                                <label>Status</label>
                                <select value={catStatus} onChange={(val) => setStatus(val.target.value)} >
                                    <option>Select Status</option>
                                    <option value={1}>Active</option>
                                    <option value={0}>Inactive</option>
                                </select>
                            </div>
                        </div>
                        {/* <div className="row"> */}
                        <div className="col-md-3">
                            <div className="formGroup">
                                <label>Price (<span>&#8377;</span>)</label>
                                <input type="number"
                                    name="amount"
                                    placeholder="Amount"
                                    onChange={(val) => setAmount(val.target.value)}
                                    value={amount}
                                    required
                                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}

                                />
                            </div>
                        </div>
                        {/* </div> */}



                    </div>
                    <div className="col-md-4">
                        <div className="btngrp">
                            <button type="button" className="themeBtn cancelBtn" onClick={() => { navigate('/subcategory'); localStorage.removeItem('editSubData') }}>Cancel</button>
                            <button type="submit" className="themeBtn">{id != undefined ? "Update" : "Submit"}</button>
                        </div>

                    </div>

                </form>

            </div>
        </div>
    )
}
export default AddEditSubCategory;