import { useState } from "react";
import React, { useEffect } from 'react';
import httpClient from "../../HTTPService";
import './OptionManagement.css';
import Loding from "../../Component/Loding/Loding";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const OptionManagement = () => {
  const navigate = useNavigate()
  let textInput = React.createRef();
  const [OptionList, setOptionList] = useState([]);
  const [searchtext, setsearchtext] = useState('')
  const [deleteOptions, setDeleteOption] = useState(null)
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1);
  const [getLimit, setLimit] = useState(10);
  const items = Array.from({ length: totalCount }, (_, index) => `Item ${index + 1}`);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(items.length / getLimit);
  const startPage = Math.max(1, currentPage - Math.floor(5 / 2));
  const endPage = Math.min(totalPages, currentPage + Math.floor(5 / 2));
  const [itemId  , setItemId] = useState('')
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    gtOptionList(searchtext, currentPage, getLimit);
  }, [currentPage, getLimit]);
  // Edit Option List 
  const editItem = (e) => {
    navigate(`/addeditoptions?id=${e?.id}`);
  }

  //pagiantion
  const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    console.log(currentPage, 'current pages...')
    return (
      <div className="pagination">
        {currentPage > 1 && (
          <button className="pagination-btn" onClick={() => onPageChange(currentPage - 1)}>
            <i className="fa fa-chevron-left" aria-hidden="true"></i>
          </button>
        )}
        {startPage > 1 && (
          <button className="pagination-btn" onClick={() => onPageChange(1)}>
            1
          </button>
        )}
        {startPage > 2 && <span className="pagination-ellipsis">...</span>}
        {pages.slice(startPage - 1, endPage).map(page => (
          <button
            key={page}
            className={`pagination-btn ${page === currentPage ? 'active' : ''}`}
            onClick={() => onPageChange(page)}
          >
            {page}
          </button>
        ))}
        {endPage < totalPages - 1 && <span className="pagination-ellipsis">...</span>}
        {endPage < totalPages && (
          <button className="pagination-btn" onClick={() => onPageChange(totalPages)}>
            {totalPages}
          </button>
        )}
        {currentPage < totalPages && (
          <button className="pagination-btn" onClick={() => onPageChange(currentPage + 1)}>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </button>
        )}
      </div>
    );
  };
  // Delete Option List
  const openModal = (id) => {
    document.getElementById('modalpop').classList.add('open');
    setItemId(id)

  }
  const deletconfirm = async () => {
    try {
      await httpClient.post('/admin/option-masters/delete', { id: itemId }, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      toast.success('Option Detele Successfully!')
      gtOptionList();
      closeBtn();
    }
    catch (error) {
      toast.error(error.message)
    }
  }
  const closeBtn = () => {
    document.getElementById('modalpop').classList.remove('open')
  }
  // Search Option List
  const searchtxt = () => {
    gtOptionList(searchtext, currentPage, getLimit);
  }
  // Get Option List
  const gtOptionList = async (search_name, pagecount, limit) => {
    try {
      const result = await httpClient.post("/admin/option-masters-list", { search_name, offset:pagecount, limit:limit }, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      setOptionList(result.data.data)
      setTotalCount(result?.data.total_count)
      // console.log(result.data);
    }
    catch (error) {

    }
  }
  return (
    <div className="OptionManagement">
      <ToastContainer  
      autoClose={1000}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div class="search-box">
              <div class="searchtct">

                <i class="fa fa-search" aria-hidden="true"></i>
                <input type="text" name="text" placeholder="Search..."
                  value={searchtext}
                  onChange={(val) => setsearchtext(val.target.value)}
                  onKeyDown={() => {
                    if (searchtext === '') {
                      gtOptionList(searchtext, currentPage, getLimit)
                    }
                  }}
                />
                <button type='button' className='searchBtn'
                  onClick={searchtxt}

                >Search</button>
                <button type='button' className='searchBtn'><Link to="/addeditoptions" >Add</Link></button>

              </div>

            </div>
          </div>
        </div>
        {OptionList ?
          // OptionList?.map((item, i) => {
          //   return <div className="col-md-3" key={i}>
          //     <div className="card">
          //       <div className="optionAction">
          //         <Link className="editActn" onClick={() => { editItem(item) }}><i class="fa fa-pencil" aria-hidden="true"></i></Link>
          //         <Link className="deleteActn" onClick={() => { openModal(item.id) }}><i class="fa fa-trash" aria-hidden="true"></i></Link>
          //       </div>
          //       <h2>{item.name}</h2>
          //       <ul className="optionList">
          //         {item?.options.map((optionItem, index) => {
          //           return <li key={index}>{optionItem.option_name}</li>
          //         })

          //         }
          //       </ul>

          //     </div>
          //   </div>
          // }) 
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>SL NO</th>
                    <th>Option Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    OptionList?.map((item, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                     
                          <td>{item?.name}</td>
                          <td>
                            <div class="action">
                              {/* <Link to={`/subscriptionview/${item?.id}`}><i class="fa fa-eye" aria-hidden="true"></i></Link> */}
                              <Link onClick={() => { editItem(item) }}><i class="fa fa-pencil" aria-hidden="true"></i></Link>
                              {/* <Link onClick={() => { openModal(item?.id) }}><i class="fa fa-trash" aria-hidden="true"></i></Link> */}

                            </div>
                          </td>
                        </tr>
                      )
                    })
                  }


                </tbody>
              </table>
            </div>
          </div>

          : <Loding />

        }

      </div>
      <div class="modalpop" id="modalpop">
        <div class="modalsbody">
          <h3>Do You Want to Delete ?</h3>
          <div class="popBtn">
            <button type="button" class="secussBtn" onClick={deletconfirm}>
              Yes
            </button>
            <button type="button" class="cancelBtn" onClick={closeBtn}>
              No
            </button>
          </div>
        </div>
      </div>
      <div class="tablepgntn">
        <p>Show <select value={getLimit} onChange={(val) => { 
          setLimit(val.target.value); 
          setCurrentPage(1)
          }}>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={40}>40</option>
          <option value={50}>50</option>
        </select> Entries</p>
        <ul>

        </ul>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />

      </div>
    </div>
  )
}
export default OptionManagement;