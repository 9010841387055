import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import httpClient from "../../HTTPService";

function HomeCmsDetails() {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search); // Parse query string
    const id = queryParams.get('id');
    const [cmsDetails, setcmsDetails] = useState()
    const [videoLink, setVideoLink] = useState('')

    useEffect(() => {
        getCmsDetails()
    }, [])
    // details
    const getCmsDetails = async () => {
        // try{
        const result = await httpClient.post('/admin/cms-view', { id: id }, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res) => {
            if (res.status === 200) {
                console.log(res?.data?.data, 'data respnese..')
                setcmsDetails(res?.data?.data)
                const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
                const matches = res.data?.data?.video_link?.match(regex);
                setVideoLink(matches[1])
            }
            console.log(res.data?.data, 'home details.....')
        });

    }
    return (
        <>
            <div className="howitsWorks">
                <div className="row">
                    {/* <div className="howitsWorks"> */}
                    <div className="col-md-12 card">
                        <h5>{cmsDetails?.title}</h5>
                        <div className="catImagettl">
                            <div className="videoTag">
                                <iframe width="380" height="200"
                                    src={`https://www.youtube.com/embed/${videoLink}`}
                                >
                                </iframe>
                            </div>
                        </div>
                        {/* </div> */}
                    </div>

                    <div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeCmsDetails