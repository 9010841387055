import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import httpClient from "../../HTTPService"

function LabelMasterList() {
    const navigate = useNavigate()
    const [labelList, setLableList] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [searchtext, setsearchtext] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [getLimit, setLimit] = useState(10);
    const items = Array.from({ length: totalCount }, (_, index) => `Item ${index + 1}`);
    const itemsPerPage = 10;
    const totalPages = Math.ceil(items.length / getLimit);
    const startPage = Math.max(1, currentPage - Math.floor(5 / 2));
    const endPage = Math.min(totalPages, currentPage + Math.floor(5 / 2));
    useEffect(() => {
        getlabelList(searchtext, currentPage, getLimit);
    }, [currentPage, getLimit]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    //pagiantion
    const Pagination = ({ currentPage, totalPages, onPageChange }) => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(i);
        }
        console.log(currentPage, 'current pages...')
        return (
            <div className="pagination">
                {currentPage > 1 && (
                    <button className="pagination-btn" onClick={() => onPageChange(currentPage - 1)}>
                        <i className="fa fa-chevron-left" aria-hidden="true"></i>
                    </button>
                )}
                {startPage > 1 && (
                    <button className="pagination-btn" onClick={() => onPageChange(1)}>
                        1
                    </button>
                )}
                {startPage > 2 && <span className="pagination-ellipsis">...</span>}
                {pages.slice(startPage - 1, endPage).map(page => (
                    <button
                        key={page}
                        className={`pagination-btn ${page === currentPage ? 'active' : ''}`}
                        onClick={() => onPageChange(page)}
                    >
                        {page}
                    </button>
                ))}
                {endPage < totalPages - 1 && <span className="pagination-ellipsis">...</span>}
                {endPage < totalPages && (
                    <button className="pagination-btn" onClick={() => onPageChange(totalPages)}>
                        {totalPages}
                    </button>
                )}
                {currentPage < totalPages && (
                    <button className="pagination-btn" onClick={() => onPageChange(currentPage + 1)}>
                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </button>
                )}
            </div>
        );
    };
    // Get Option List
    const getlabelList = async (search_name, pagecount, limit) => {
        try {
            const result = await httpClient.post("/admin/input-labels-list", { search_name, offset: pagecount, limit: limit }, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            setLableList(result.data.data)
            setTotalCount(result?.data.total_count)
            // console.log(result.data);
        }
        catch (error) {

        }
    }
    const searchtxt = () => {
        getlabelList(searchtext, currentPage, getLimit);
    }

    const editItem = (e) => {
        // console.log(e);
        let paginationState = {
            pageCurrent: currentPage,
            limit: getLimit,

        }
        navigate(`/labelmaster-add-edit?id=${e?.id}`, {
            state: {
                paginationState
            }
        });
        //  localStorage.setItem('editSubData',editSub)
    }
    return (
        <>
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div class="search-box">
                                <div class="searchtct">

                                    <i class="fa fa-search" aria-hidden="true"></i>
                                    <input type="text" name="text" placeholder="Search..."
                                        value={searchtext}
                                        onChange={(val) => setsearchtext(val.target.value)}
                                        onKeyDown={() => {
                                            if (searchtext === '') {
                                                getlabelList(searchtext, currentPage, getLimit)
                                            }
                                        }}
                                    />
                                    <button type='button' className='searchBtn'
                                        onClick={searchtxt}

                                    >Search</button>
                                    <button type='button' className='searchBtn'><Link to="/labelmaster-add-edit" >Add</Link></button>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>SL NO</th>
                                        <th>Label Name</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        labelList?.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.name}</td>
                                                    <td>
                                                        <div class="action">
                                                            {/* <Link to={`/subscriptionview/${item?.id}`}><i class="fa fa-eye" aria-hidden="true"></i></Link> */}
                                                            <Link
                                                                onClick={() => { editItem(item) }}

                                                            ><i class="fa fa-pencil"
                                                                aria-hidden="true"></i></Link>
                                                            {/* <Link onClick={() => { openModal(item?.id) }}><i class="fa fa-trash" aria-hidden="true"></i></Link> */}

                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="tablepgntn">
                    <p>Show <select value={getLimit} onChange={(val) => {
                        setLimit(val.target.value);
                        setCurrentPage(1)
                    }}>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                        <option value={40}>40</option>
                        <option value={50}>50</option>
                    </select> Entries</p>
                    <ul>

                    </ul>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />

                </div>
            </div>
        </>
    )
}

export default LabelMasterList