import group from '../../assets/group.png';
import onlinepayment from '../../assets/online-payment.png';
import email from '../../assets/email.png';
import payment from '../../assets/payment.png';
import employe from '../../assets/employe.png';
import banUser from '../../assets/ban-user.png';
import kyc from "../../assets/download_kyc.png"
import { useEffect, useRef } from 'react';
import httpClient from '../../HTTPService';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import bookBuy from "../../Redux/Action/ActionFunction"
import './Dashboard.css';
import { useNavigate } from 'react-router-dom';
const Dashboard = () => {
  const [userData, setuserData] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    getDashboardData();
  }, [])


  const getDashboardData = async () => {

    try {
      const result = await httpClient.post('/admin/dashboard', {}, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });
      console.log(result.data.data);

      if (result.data.status) {
        // toast.success("Deta featch Successfully!")
        setuserData(result.data.data)
      }
    } catch (error) {
      // toast.error(error.message)
    }
  }
  return (
    <div className="dashboard">
      <ToastContainer  
      autoClose={1000}
      />
      {/* {userDatas?.numberofBook} <button type='button'onClick={()=>(dispatch(bookBuy(1)))}>Update</button> */}
      <div className="row">
        <div className="col-md-4"
          onClick={() => navigate('/member')}
        >
          <div className="card">
            <h2>Total Registered members</h2>
            <div className="cardBtm">
              <img src={group} />
              <span>{userData ? userData?.total_members : <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>}</span>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card" 
          onClick={() => navigate('/alluser',{
            state:{
              unpaid:''
            }
          })}
          >
            <h2>Unpaid member</h2>
            <small className="inactive">Inactive</small>
            <div className="cardBtm">
              <img src={onlinepayment} />
              <span>{userData ? userData?.non_paid_members : <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>}</span>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card" onClick={() => navigate('/member')}>
            <h2>Paid Member</h2>
            <small className="sucess">Active</small>
            <div className="cardBtm">
              <img src={onlinepayment} />
              <span>{userData ? userData?.paid_members : <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>}</span>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card flxcard">
            <div onClick={() => navigate('/help')}>
              <h2>Customer Queries</h2>
              <div className="cardBtm">
                <img src={email} />
                <span>{userData ? userData?.customer_query : <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>}</span>
              </div>
            </div>
            <div onClick={() => navigate('/help', {
              state: {
                name: 0
              }
            })}>
              <h2>Not Replay</h2>
              <div className="cardBtm">
                <span className="blocknumber">{userData ? userData?.not_reply : <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card flxcard">
            <div onClick={() => navigate('/withdrawallist')}>
              <h2>Payout Request</h2>
              <div className="cardBtm">
                <img src={payment} />
                <span>{userData ? userData?.payment_request : <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>}</span>

              </div>
            </div>
            <div onClick={() => navigate('/withdrawallist', {
              state: {
                unpaid: 3
              }
            })}>
              <h2>Unpaid</h2>
              <div className="cardBtm">
                <span className="blocknumber">{userData ? userData?.unpaid : <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card flxcard">
            <div>
              <h2>Add Profile Request</h2>
              <div className="cardBtm">
                <img src={employe} />
                <span>{userData ? userData?.profile_request : <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>}</span>
              </div>
            </div>
            <div>
              <h2>Unapprove</h2>
              <div className="cardBtm">
                <span className="blocknumber">{userData ? userData?.unapproved : <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <h2>Weekly Report</h2>
            <div className="cardBtm">
              <ul>
                <li onClick={() => navigate('/reports')}><span>1</span></li>
                <li onClick={() => navigate('/reports')}><span>2</span></li>
                <li onClick={() => navigate('/reports')}><span>3</span></li>
                <li onClick={() => navigate('/reports')}><span>4</span></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card" onClick={() => navigate('/alluser', {
            state: {
              name: 0
            }
          })}>
            <h2>Blocked Member</h2>
            <div className="cardBtm">
              <img src={banUser} />
              <span>{userData ? userData?.block_members : <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>}</span>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card" onClick={() => navigate('/profit')}>
            <h2>Total Distribution</h2>

            <div className="cardBtm">
              <img src={payment} />
              <span>{userData ? userData?.total_payment : <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>}</span>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card flxcard">
            <div onClick={() => navigate('/profit')}>
              <h2>Total Revenue</h2>
              <div className="cardBtm">
                <img src={payment} />
                <span> {userData?.total_revenue}
                  {/* <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i> */}
                </span>
              </div>
            </div>
            <div onClick={() => navigate('/profit')}>
              <h2>Net Profit</h2>
              <div className="cardBtm">
                <span className="blocknumber">
                  {userData?.net_profit}
                  {/* {userData? userData?.not_reply :  */}
                  {/* <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i> */}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card flxcard">
            <div>
              <h2>KYC Request</h2>
              <div className="cardBtm">
                <img src={kyc} />
                <span>
                  {userData?.kyc_request}
                  {/* {userData? userData?.customer_query :  */}
                  {/* <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i> */}
                  {/* } */}
                </span>
              </div>
            </div>
            <div>
              <h2>Unapprove</h2>
              <div className="cardBtm">
                <span className="blocknumber">{userData ? userData?.kyc_unapproved : <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Dashboard;