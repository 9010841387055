import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import httpClient from "../../HTTPService";
import { useState } from "react";
import './Category.css';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import moment from "moment";
const SubCategoryDetails = () => {
    const navigate = useNavigate();
    const [getcatDetail, setgetcatDetails] = useState();
    const params = useParams();
    const { id } = params
    // console.log(id);
    // edit
    const editdata = (e) => {
        navigate('/addsubcategory');
        const edit = JSON.stringify(e)
        JSON.stringify(localStorage.setItem('editSubData', edit))
    }

    // get cat details
    useEffect(() => {
        getcatDetails();
    }, [])
    const getcatDetails = async () => {
        try {
            const result = await httpClient.post("/admin/sub-categories/view", { id: id }, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (result.data.status) {
                setgetcatDetails(result.data.data)
            }
        } catch (error) {
            // toast.error(error.message)
        }
    }

    return (
        <div className="CategoryDetails">
            <ToastContainer
                autoClose={1000}
            />
            <div className="card">
                <div class="row">
                    <div className="col-md-12">
                        <div className="catImagettl">
                            <div className="actioncat">
                                <Link to="" className="editcat"><i class="fa fa-pencil" aria-hidden="true" onClick={() => { editdata(getcatDetail) }}></i></Link>
                                <Link to="/subcategory" className="backcat" ><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>
                            </div>

                            <div className="catimg">
                                <img src={getcatDetail?.image_path} />
                            </div>
                            <h2>{getcatDetail?.name}</h2>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="catDetails">
                            <h3>Drescption</h3>
                            <p> <label>Amount </label> : <span>&#8377;</span>{getcatDetail?.amount}</p>
                            <p>{getcatDetail?.description}</p>
                            <p><label>Created Date :</label>{moment(getcatDetail?.created_at).format('DD-MM-YYYY')} {moment(getcatDetail?.created_at).format('LT')}</p>
                            <p><label>Updated Date :</label>{moment(getcatDetail?.updated_at).format('DD-MM-YYYY')} {moment(getcatDetail?.updated_at).format('LT')}</p>
                            <p><label>Status :</label><span className={getcatDetail?.active ? "active" : "inactive"}>{getcatDetail?.active ? "Active" : "Inactive"}</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default SubCategoryDetails;