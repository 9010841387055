import { useEffect, useMemo, useState } from "react";
import httpClient from "../HTTPService";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from "react-router-dom";

function WidthdrawList() {

    const [withdrawlistData, setwithdralistData] = useState([])
    const [originalWithdraList, setOriginalWithdraList] = useState([])
    const [optionSelect, setoptionselect] = useState('')
    const [selectedid, setselectedid] = useState('')
    const [status, setStatus] = useState('')
    const [searchtext, setsearchtext] = useState('')
    const [totalCount, setTotalCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const location = useLocation()
    const [getLimit, setLimit] = useState(10);
    const items = Array.from({ length: totalCount }, (_, index) => `Item ${index + 1}`);
    const itemsPerPage = 10;
    const totalPages = Math.ceil(items.length / getLimit);
    const startPage = Math.max(1, currentPage - Math.floor(5 / 2));
    const endPage = Math.min(totalPages, currentPage + Math.floor(5 / 2));
    const [filter, setfilter] = useState(4)
    const [fetchUnpaid, setFetchPaid] = useState(true)

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const searchtxt = () => {
        gteWithdrawlist(searchtext, 1, getLimit);
    }
    // pagination
    const Pagination = ({ currentPage, totalPages, onPageChange }) => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(i);
        }
        console.log(currentPage, 'current pages...')
        return (
            <div className="pagination">
                {currentPage > 1 && (
                    <button className="pagination-btn" onClick={() => onPageChange(currentPage - 1)}>
                        <i className="fa fa-chevron-left" aria-hidden="true"></i>
                    </button>
                )}
                {startPage > 1 && (
                    <button className="pagination-btn" onClick={() => onPageChange(1)}>
                        1
                    </button>
                )}
                {startPage > 2 && <span className="pagination-ellipsis">...</span>}
                {pages.slice(startPage - 1, endPage).map(page => (
                    <button
                        key={page}
                        className={`pagination-btn ${page === currentPage ? 'active' : ''}`}
                        onClick={() => onPageChange(page)}
                    >
                        {page}
                    </button>
                ))}
                {endPage < totalPages - 1 && <span className="pagination-ellipsis">...</span>}
                {endPage < totalPages && (
                    <button className="pagination-btn" onClick={() => onPageChange(totalPages)}>
                        {totalPages}
                    </button>
                )}
                {currentPage < totalPages && (
                    <button className="pagination-btn" onClick={() => onPageChange(currentPage + 1)}>
                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </button>
                )}
            </div>
        );
    };
    useEffect(() => {
        gteWithdrawlist(searchtext, currentPage, getLimit)
    }, [currentPage, getLimit])
    const gteWithdrawlist = async (search_name, pagecount, limit) => {
        try {
            const result = await httpClient.post("/admin/withdraw-list", {
                search_name, search_status: location?.state?.unpaid != undefined ? location?.state?.unpaid
                    : filter == 4 ? null : filter,

                offset: pagecount, limit: limit
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            console.log(result.data.data);
            if (result.data.status == true) {
                setwithdralistData(result?.data?.data)
                setTotalCount(result?.data.total_count)
                location.state = null
                // toast.success(result.data.message)
            }
            if (result.data.status == false) {
                // toast.error(result.data.message)

            }

        }

        catch (error) {
            // toast.error(error.message)
        }
    }


    function viewDetails(data) {
        document.getElementById('modalpop').classList.add('open')
        // setItemDetails(data)
        setselectedid(data?.id)
        setStatus(data?.status)
        setoptionselect(data?.status)
        // setdeleteyes(id)
    }
    function cancelModel() {
        document.getElementById('modalpop').classList.remove('open')
        // setItemDetails('')
    }

    function submitRequest() {

        let body = {
            id: selectedid,
            status: optionSelect
        }
        try {
            const result = httpClient.post("/admin/withdraw-approve-request", JSON.stringify(body), {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then((res) => {
                if (res.status === 200) {
                    toast.success(res.data.message)
                    gteWithdrawlist()
                    document.getElementById('modalpop').classList.remove('open')
                }
            });


        }

        catch (error) {
            // toast.error(error.message)
        }

    }
    useMemo(() => {
   console.log(filter,'filtered...')
        gteWithdrawlist()
        return () => { }

    }, [filter])
    return (
        <>
            <div className="SubscriptionDetails">
                <ToastContainer 
                autoClose={1000}
                />

                {/* <div className=''> */}

                <div class="search-box">
                    <div class="searchtct ">

                        <i class="fa fa-filter" aria-hidden="true"></i>
                        <select
                            value={filter}
                            // className='ml-3'
                            // disabled={chooseFldType === '' ? true : false}
                            onChange={(val) => { setfilter(val.target.value) }}
                        //   className={(isrequired && labelvalue === null) ? 'required' :'' }
                        >
                            <option value={4} >All</option>
                            <option value={1}>Approve</option>
                            <option value={0}>Pending</option>
                            <option value={2}>Cancel</option>
                        </select>

                    </div>
                    <div class="searchtct">

                        <i class="fa fa-search" aria-hidden="true"></i>
                        <input type="text" name="text" placeholder="Search..."
                            onChange={(val) => setsearchtext(val.target.value)}
                            onKeyDown={() => {
                                if (searchtext === '') {
                                    gteWithdrawlist(searchtext, currentPage, getLimit)
                                }
                            }}
                        />
                        <button type='button' className='searchBtn' onClick={searchtxt} >Search</button>


                    </div>

                </div>
                {/* </div> */}
                {/* <div className="card"> */}
                <div class="row">
                    <div className="col-md-12">
                        {/* <div className="catDetails"/> */}
                        <div>
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>SL NO</th>
                                            <th>Account Holder Name</th>
                                            <th>Bank Name</th>
                                            <th>Account No</th>
                                            {/* <th>Branch Name</th> */}
                                            <th>IFSC Code</th>
                                            <th>Amount</th>
                                            <th>Created At</th>
                                            <th>Status</th>
                                            <th>Approve</th>
                                            {/* <th>Status</th> */}

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            withdrawlistData?.map((item, index) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{item?.bank?.account_holder_name !== null ? item?.bank?.account_holder_name : '--'}</td>
                                                            <td>{item?.bank?.bank_name !== null ? item?.bank?.bank_name : '--'}</td>
                                                            <td>{item?.bank?.account_no !== null ? item?.bank?.account_no : '--'}</td>
                                                            {/* <td>{item?.bank?.branch_name !== null ? item?.bank?.branch_name : '--'}</td> */}
                                                            <td>{item?.bank?.ifsc_code !== null ? item?.bank?.ifsc_code : '--'}</td>
                                                            <td>{item?.amount}</td>
                                                            <td>{`${moment(item?.created_at).format('DD-MM-YYYY')} ${moment(item?.created_at).format('LT')}`}</td>
                                                            <td>

                                                                <h6
                                                                    className={item?.status == 0 ?
                                                                        "pending_color" : item?.status == 2 ?
                                                                            'cancelcolor' : 'approvecolor'

                                                                    }


                                                                >

                                                                    {item?.current_status}</h6>

                                                            </td>
                                                            <td>
                                                                <div class="flex_withdraw">
                                                                    <i class="fa fa-eye"
                                                                        onClick={() => viewDetails(item)}
                                                                        aria-hidden="true"></i>

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            })

                                        }
                                    </tbody>
                                </table>
                                <div className='nodata-found-div'>
                                    {
                                        withdrawlistData?.length == 0 ?
                                            <p>No Data Found</p>
                                            //  <img 
                                            //  className='table-image'
                                            //  src={require('../../assets/OIP.png')}/>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                    </div>
                </div>
                <div class="modalpop" id="modalpop" >
                    <div class="modalsbody">
                        <div> <p>Widthdraw Status Changes</p></div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-8">
                                <div className="formGroup">
                                    {/* <label>Withdraw Status</label> */}
                                    <select
                                        id=""
                                        value={optionSelect}
                                        onChange={(val) => setoptionselect(val.target.value)}
                                    >
                                        <option>Select Withdraw Status</option>

                                        <option value="2">Cancel</option>
                                        <option value="1">Approve</option>

                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="popBtn">
                            <button type="button" disabled={(status == '1' || status == '2') ? true : false}

                                class={(status == '1' || status == '2') ? "button-with" : "secussBtn"}
                                onClick={submitRequest}>
                                Submit
                            </button>
                            <button type="button" class="cancelBtn" onClick={cancelModel}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
                {/* </div> */}
                <div class="tablepgntn">
                    <p>Show <select value={getLimit} onChange={(val) => { 
                        setLimit(val.target.value);
                        setCurrentPage(1)
                        }}>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                        <option value={40}>40</option>
                        <option value={50}>50</option>
                    </select> Entries</p>
                    <ul>
                        {/* {currentItems.map(item => (
          <li key={item}>{item}</li>
        ))} */}
                    </ul>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                    {/* <ul>
               
                <li class="active">
                  <Link onClick={() => { setoffsetValue(0) }}>01</Link>

                </li>
                <li>
                  <Link onClick={() => { setoffsetValue(10) }}>02</Link>

                </li>
                <li>
                  <Link onClick={() => { setoffsetValue(20) }}>03</Link>

                </li>
                <li>
                  <Link onClick={() => { setoffsetValue(30) }}>04</Link>

                </li>
               
              </ul> */}
                </div>

            </div>
        </>
    )
}
export default WidthdrawList