import React, { useState } from "react";
import { json, useLocation, useNavigate } from "react-router-dom";
import './OptionManagement.css';
import { useEffect } from "react";
import httpClient from "../../HTTPService";
import { ToastContainer, toast } from 'react-toastify';
const AddEditOption = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const [optionName, setOptionName] = useState('');
    const [optionValue, setOptionValue] = useState('');
    const [editMode, setEditMode] = useState();
    const [editid, setEditID] = useState();
    const [optionData, setOptiondata] = useState([]);
    const queryParams = new URLSearchParams(location.search); // Parse query string
    const id = queryParams.get('id');
    // let textInput = React.createRef()
    function ondeleteItems(indexs) {
        let _modifiedarray = optionData?.filter((obj, index) => index !== indexs)
        setOptiondata(_modifiedarray)
    }

    useEffect(() => {
        if (id != undefined) {
            // setloadinigstate(true)
            httpClient.post("/admin/option-masters/view", {
                "id": id
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then((res) => {

                setOptionName(res?.data?.data?.name)
                let _setactualData = res?.data?.data?.options?.map((item) => {
                    return {
                        id: item?.id,
                        name: item?.option_name
                    }
                })
                setOptiondata(_setactualData)
            })

        }

        // console.log(result ,'category details')
    }, [id])



    const updateoption = (index1, optionValue) => {
        let data = [...optionData]
        data[index1].name = optionValue?.target?.value
        setOptiondata(data)

    };
    const addoption = () => {
        setOptiondata(prevOptionData => {
            if (prevOptionData.length > 0) {
                return [...prevOptionData, { id: null, name: optionValue }];
            } else {
                return [{ id: null, name: optionValue }];
            }
        });
        setOptionValue('');
    };
    const addOptionForm = async () => {
        console.log(optionData, 'option data')
        if (id != undefined) {

            try {
                const result = await httpClient.post("/admin/option-masters/update", { id: id, name: optionName, option_data: optionData }, {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                })
                if (result.data.status == true) {
                    setTimeout(() => {
                        navigate('/optionmanagement')
                    })
                    toast.success("Option Update Successfully")
                }

            }
            catch (error) {
                toast.error(error.message)
            }
        }
        else {
            try {
                const result = await httpClient.post("/admin/option-masters/create", { name: optionName, option_data: optionData }, {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                })
                if (result.data.status == true) {
                    setTimeout(() => {
                        navigate('/optionmanagement')
                    })
                    toast.success("Option Create Successfully")
                }
                if (result.data.status == false) {
                    toast.error(result.data.message)
                }
            }
            catch (error) {
                toast.error(error.message)
            }
        }

    }
    return (
        <div className="AddEditOption">
            <ToastContainer  
            autoClose={1000}
            />
            <div className="card">

                <div className="row">
                    <div className="col-md-4">
                        <div className="formGroup">
                            <label>Option Name</label>
                            <input type="text" name="optionName" placeholder="Name" onChange={(val) => setOptionName(val.target.value)} value={optionName} required />
                        </div>
                    </div>
                </div>
                <div className="scrollBar">
                    {/* {optionData? optionData?.map((item, index)=>{
                         return  <div className="row" key={index}>
                         <div className="col-md-4">
                            <div className="formGroup ">
                            <input type="text" name="optionValue" placeholder="Option Value" value={item.name}/>
                            </div>
                        </div>
                        </div>
                     
                       
                    })} */}
                    {

                        // editMode ?
                        // optionData?.map((item, index) => {
                        //     return <div className="row" key={index}>
                        //         <div className="col-md-4">
                        //             <div className="formGroup ">
                        //                 <input type="text" name="optionValue" placeholder="Option Value" defaultValue={item.name} onChange={(val) => { updateoption(index, val.target.value) }} />
                        //             </div>
                        //         </div>
                        //     </div>

                        // }) :
                        optionData?.map((item, index) => {
                            return <div className="row" key={index}>
                                <div className="col-md-4">
                                    <div className="formGroup ">
                                        <div className="item-list">
                                            <input type="text"
                                                name="optionValue" placeholder="Option Value"
                                                value={item.name}
                                                onChange={(val) => { updateoption(index, val) }}

                                            />
                                            {/* <div className="cross-icon" onClick={() => ondeleteItems(index)}>
                                                <div className="">
                                                    <p className="icon">X</p>

                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>


                        })

                    }
                </div>


                <div className="row">
                    <div className="col-md-4">

                        <div className="formGroup flxFrm">
                            <input type="text" name="optionValue" value={optionValue} placeholder="Option Value" onChange={(val) => { setOptionValue(val.target.value) }} />
                            <button type="button" className="addBtn"
                                disabled={optionValue === '' ? true : false}
                                onClick={addoption} >Add</button>
                        </div>


                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <div className="btngrp">
                            <button type="button" className="themeBtn cancelBtn" onClick={() => { navigate('/optionmanagement'); localStorage.removeItem('editOptions') }}>Cancel</button>
                            <button type="submit" className="themeBtn"
                                disabled={optionName === '' ? true : false}
                                onClick={addOptionForm}>{editMode ? "Update" : "Submit"}</button>
                        </div>
                    </div>
                </div>



            </div>
        </div>

    )
}
export default AddEditOption;