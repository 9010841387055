
import httpClient from '../../HTTPService';
import './Subscription.css';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Loding from '../../Component/Loding/Loding';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
const Subscription = () => {
  const [offsetValue, setoffsetValue] = useState(0);
  const navigate = useNavigate();
  const [deleteyes, setdeleteyes] = useState(null)
  const [getSubscription, setSubscription] = useState();
  let textInput = React.createRef();
  const [getLimit, setLimit] = useState(10);
  const [searchtext,setsearchtext]  = useState('')
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1);
  const items = Array.from({ length: totalCount }, (_, index) => `Item ${index + 1}`);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(items.length / getLimit);
  const startPage = Math.max(1, currentPage - Math.floor(5 / 2));
  const endPage = Math.min(totalPages, currentPage + Math.floor(5 / 2));
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const startIndex = (currentPage - 1) * getLimit;
  const currentItems = items.slice(startIndex, startIndex + getLimit);
// pagination
const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pages = [];
  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }
  console.log(currentPage, 'current pages...')
  return (
    <div className="pagination">
    {currentPage > 1 && (
      <button className="pagination-btn" onClick={() => onPageChange(currentPage - 1)}>
        <i className="fa fa-chevron-left" aria-hidden="true"></i>
      </button>
    )}
    {startPage > 1 && (
      <button className="pagination-btn" onClick={() => onPageChange(1)}>
        1
      </button>
    )}
    {startPage > 2 && <span className="pagination-ellipsis">...</span>}
    {pages.slice(startPage - 1, endPage).map(page => (
      <button
        key={page}
        className={`pagination-btn ${page === currentPage ? 'active' : ''}`}
        onClick={() => onPageChange(page)}
      >
        {page}
      </button>
    ))}
    {endPage < totalPages - 1 && <span className="pagination-ellipsis">...</span>}
    {endPage < totalPages && (
      <button className="pagination-btn" onClick={() => onPageChange(totalPages)}>
        {totalPages}
      </button>
    )}
    {currentPage < totalPages && (
      <button className="pagination-btn" onClick={() => onPageChange(currentPage + 1)}>
        <i className="fa fa-chevron-right" aria-hidden="true"></i>
      </button>
    )}
  </div>
  );
};


// const updatestatus = (data) => {
//   console.log(data, 'data...')
//   try {
//       httpClient.post('/admin/subscriptions/change-status', {
//           id: data?.id,
//           status: data?.status
//       }, {
//           headers: {
//               "Content-Type": "application/json",
//               "Accept": "application/json",
//               'Authorization': `Bearer ${localStorage.getItem('token')}`
//           }
//       }).then((res) => {
//           if (res.status === 200) {
//               getSub(searchtext,currentPage,getLimit)
//           }
//       })

//       // if (result.status === 200) {
//       //     console.log(result.data.data, 'data..')
//       //     setTotalCount(result?.data.total_count)
//       //     setReviewlist(result?.data?.data)
//       // }

//   } catch (error) {
//       //   toast.error(error.message)
//   }
// };
  useEffect(() => {
    getSub(searchtext,currentPage,getLimit);
  }, [currentPage, getLimit])
  // Edit Subcription
  const editItem = (e) => {
    console.log(e);
    // const editSub = JSON.stringify(e)
    navigate(`/addeditsubscription?id=${e?.id}`,);
    //  localStorage.setItem('editSubData',editSub)
  }
  // Delete subscription
  const deletconfirm = async () => {
    try {
      await httpClient.post('/admin/subscriptions/delete', { id: deleteyes }, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      toast.success('Subscription Detele Successfully!')
      getSub();
      closeBtn();

    }
    catch (error) {
      toast.error(error.message)
    }

  }
  // search Subcription
  const searchtxt = () => {
    getSub(searchtext ,1,getLimit);
  }
  // get Subcription

  const getSub = async (search_name, pagecount, limit) => {
    try {
      const result = await httpClient.post("/admin/subscriptions-list", { search_name, offset: pagecount, limit: limit }, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      if (result.data.status && result.data.data.length !== 0) {
        // toast.success("Subscription featch Successfully!")
        setSubscription(result.data.data);
        setTotalCount(result?.data.total_count)
      }
      if (result.data.data.length === 0) {
        toast.error("No Subscription Found")
        setSubscription(result.data.data);
      }
    } catch (error) {
      toast.error(error.message)
    }


  }

  const deleteBtn = (id) => {
    document.getElementById('modalpop').classList.add('open')
    setdeleteyes(id)
  }
  const closeBtn = () => {
    document.getElementById('modalpop').classList.remove('open')
  }
  return (
    <div className='subscription'>
      <div class="row">
        <div class="col-md-12">
          <div class="search-box">
            <div class="searchtct">

              <i class="fa fa-search" aria-hidden="true"></i>
              <input 
              type="text" 
              name="text"
               placeholder="Search..." 
               onChange={(val) => setsearchtext(val.target.value)} 
               onKeyDown={() => {
                if(searchtext === ''){
                  getSub(searchtext,currentPage,getLimit)
                }
              }}
               />
              <button type='button' className='searchBtn' onClick={searchtxt}>Search</button>
              <button type='button' className='searchBtn'><Link to="/addeditsubscription" >Add</Link></button>

            </div>

          </div>
          {getSubscription ? <div>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>SL NO</th>
                    <th>Name</th>
                    
                    <th>Date</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                
                  {getSubscription?.map((item, i) => {
                    return <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{item.name ? item.name : "--"}</td>
                     
                      <td>{`${moment(item?.subscriptions[0].created_at).format('DD-MM-YYYY')}`}</td>


                      {/* <td>

                        <div
                          className={`switch ${item?.active === 1 ? 'on' : 'off'}`}
                          onClick={() => updatestatus(
                            {
                              id: item?.id,
                              status: item?.active === 1 ? 0 : 1
                            }
                          )}
                          role="switch"
                          aria-checked={item?.active}
                          aria-label="Toggle switch"
                        >
                          <div className="switch-handle" />
                        </div>
                      </td> */}
                      <td>
                        <span className={item?.subscriptions[0].active == 1 ? "active" : "inactive"}>{item?.subscriptions[0].active == 1 ? "Active" : "Inactive"}</span>
                      </td>
                      <td>
                        <div class="action">
                          <Link to={`/subscriptionview/${item?.id}`}><i class="fa fa-eye" aria-hidden="true"></i></Link>
                          <Link onClick={() => { editItem(item) }}><i class="fa fa-pencil" aria-hidden="true"></i></Link>
                          <Link onClick={() => { deleteBtn(item?.id) }}><i class="fa fa-trash" aria-hidden="true"></i></Link>

                        </div>
                      </td>
                    </tr>
                  })
                  }
                </tbody>
              </table>
              <div className='nodata-found-div'>
                           {
                                    getSubscription?.length == 0 ?
                                    <p>No Data Found</p>
                                        //  <img 
                                        //  className='table-image'
                                        //  src={require('../../assets/OIP.png')}/>
                                        : null
                                }
                           </div>
            </div>
            <div class="tablepgntn">
              <p>Show <select value={getLimit} onChange={(val) => {
                 setLimit(val.target.value); 
                 setCurrentPage(1)
                 }}>
                <option>10</option>
                <option>20</option>
                <option>30</option>
                <option>40</option>
                <option>50</option>
              </select> Entries</p>
              <ul>
                 
              </ul>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div> :
            <Loding />

          }

        </div>
      </div>
      <div class="modalpop" id="modalpop">
        <div class="modalsbody">
          <h3>Do You Want to Delete ?</h3>
          <div class="popBtn">
            <button type="button" class="secussBtn" onClick={deletconfirm}>
              Yes
            </button>
            <button type="button" class="cancelBtn" onClick={closeBtn}>
              No
            </button>
          </div>
        </div>
      </div>
    </div>

  )
}
export default Subscription;