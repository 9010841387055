
import './App.css';
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Outlet
} from "react-router-dom";
import { Provider } from 'react-redux';
import store from "./Redux/Store/Store"
import { useCallback, useEffect } from 'react';

function App() {
  return (
    <Provider store={store} >
      <div className="App">

        <Outlet />
      </div>
    </Provider>

  );
}

export default App;
