import React, { useEffect } from 'react';
import { Link, Route } from 'react-router-dom';
import Loding from '../../Component/Loding/Loding';
import httpClient from '../../HTTPService';
import { useState } from 'react';
import './Category.css';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const SubCategory = () => {
  const [offsetValue, setoffsetValue] = useState(0);
  const navigate = useNavigate()
  const [getLimit, setLimit] = useState(10);
  const [deleteyes, setdeleteyes] = useState(null)
  const [getcat, setgetCat] = useState()
  let textInput = React.createRef();
  const [searchtext, setsearchtext] = useState('')
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1);
  const items = Array.from({ length: totalCount }, (_, index) => `Item ${index + 1}`);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(items.length / getLimit);
  const startPage = Math.max(1, currentPage - Math.floor(5 / 2));
  const endPage = Math.min(totalPages, currentPage + Math.floor(5 / 2));
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const startIndex = (currentPage - 1) * getLimit;
  const currentItems = items.slice(startIndex, startIndex + getLimit);

  useEffect(() => {
    getCat(searchtext, currentPage, getLimit);
  }, [currentPage, getLimit]);
  // edit Item 
  const editItem = (e) => {
    console.log(e);
    // const editdata = JSON.stringify(e)
    navigate(`/addsubcategory?id=${e.id}`);
    // localStorage.setItem('editSubData', editdata)
  }
  // Search Category
  const searchtxt = () => {
    getCat(searchtext, 1, getLimit);
  }
  // Delete Category
  const deleteBtn = (id) => {
    document.getElementById('modalpop').classList.add('open')
    setdeleteyes(id)

  }
  const deletconfirm = async () => {
    try {
      const result = await httpClient.post('/admin/sub-categories/delete', { id: deleteyes }, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (result.data.status == true) {
        toast.success('Sub Category Detele Successfully!')
        getCat();
        closeBtn();
      }
      if (result.data.status == false) {
        toast.error(result.data.message)
        closeBtn();
      }


    }
    catch (error) {
      toast.error(error.message)
    }

  }

  //pagination
  const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    console.log(currentPage, 'current pages...')
    return (
      <div className="pagination">
        {currentPage > 1 && (
          <button className="pagination-btn" onClick={() => onPageChange(currentPage - 1)}>
            <i className="fa fa-chevron-left" aria-hidden="true"></i>
          </button>
        )}
        {startPage > 1 && (
          <button className="pagination-btn" onClick={() => onPageChange(1)}>
            1
          </button>
        )}
        {startPage > 2 && <span className="pagination-ellipsis">...</span>}
        {pages.slice(startPage - 1, endPage).map(page => (
          <button
            key={page}
            className={`pagination-btn ${page === currentPage ? 'active' : ''}`}
            onClick={() => onPageChange(page)}
          >
            {page}
          </button>
        ))}
        {endPage < totalPages - 1 && <span className="pagination-ellipsis">...</span>}
        {endPage < totalPages && (
          <button className="pagination-btn" onClick={() => onPageChange(totalPages)}>
            {totalPages}
          </button>
        )}
        {currentPage < totalPages && (
          <button className="pagination-btn" onClick={() => onPageChange(currentPage + 1)}>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </button>
        )}
      </div>
    );
  };
  // get Sub Category
  const getCat = async (search_name, pagecount, limit) => {
    try {
      const result = await httpClient.post('/admin/sub-categories-list', { search_name, offset: pagecount, limit: limit }, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (result.data.status && result.data.data.length !== 0) {
        // toast.success("Sub Category featch Successfully!")
        setgetCat(result.data.data);
        setTotalCount(result.data?.total_count)
      }
      if (result.data.data.length === 0) {
        toast.error("No Sub Category Found")
        setgetCat(result.data.data)
      }

    }
    catch (error) {
      console.log(error);
    }
  }

  const closeBtn = () => {
    document.getElementById('modalpop').classList.remove('open')
  }

  return (
    <div className="category">
      <ToastContainer 
      autoClose={1000}
      />
      <div class="row">
        <div class="col-md-12">
          <div class="search-box">
            <div class="searchtct">

              <i class="fa fa-search" aria-hidden="true"></i>
              <input
                type="text"
                name="text"
                placeholder="Search..."
                onChange={(val) => setsearchtext(val.target.value)}
                onKeyDown={() => {
                  if (searchtext === '') {
                    getCat(searchtext, currentPage, getLimit)
                  }
                }}
              />
              <button
                type='button'
                className='searchBtn'
                onClick={searchtxt}
              >Search</button>
              <button type='button' className='searchBtn'><Link to="/addsubcategory" >Add</Link></button>

            </div>

          </div>
          {getcat ?
            <div>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>SL NO</th>
                      <th>Categoty Image</th>
                      <th>Categoty Name</th>
                      <th>Sub Categoty Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>

                    {
                      getcat?.map((item, i) => {
                        return <tr key={i}>
                          <td>{i + 1}</td>
                          <td>
                            <div className='catIcon'>
                              <img src={item?.image_path} alt={item?.image} />
                            </div>
                          </td>
                          <td>{item?.category ? item?.category.name : "--"}</td>
                          <td>{item?.name ? item?.name : "--"}</td>
                          <td>
                            <div class="action">
                              <Link title='View Category' to={`/subcategoryview/${item?.id}`}><i class="fa fa-eye" aria-hidden="true"></i></Link>
                              <Link title='Edit Category' onClick={() => { editItem(item) }}><i class="fa fa-pencil" aria-hidden="true"></i></Link>
                              <Link title='Delete Category' onClick={() => { deleteBtn(item?.id) }}><i class="fa fa-trash" aria-hidden="true"></i></Link>

                            </div>
                          </td>
                        </tr>
                      })
                    }

                  </tbody>
                </table>
                <div className='nodata-found-div'>
                  {
                    getcat?.length == 0 ?
                      <p>No Data Found</p>
                      //  <img 
                      //  className='table-image'
                      //  src={require('../../assets/OIP.png')}/>
                      : null
                  }
                </div>
              </div>
              <div class="tablepgntn">
                <p>Show <select value={getLimit} onChange={(val) => { 
                  setLimit(val.target.value);
                  setCurrentPage(1)
                  }}>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                  <option value={50}>50</option>
                </select> Entries</p>
                <ul>

                </ul>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </div> :
            <Loding />
          }




        </div>
      </div>
      <div class="modalpop" id="modalpop">
        <div class="modalsbody">
          <h3>Do You Want to Delete ?</h3>
          <div class="popBtn">
            <button type="button" class="secussBtn" onClick={deletconfirm}>
              Yes
            </button>
            <button type="button" class="cancelBtn" onClick={closeBtn}>
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubCategory;