import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import httpClient from "../../HTTPService"
import noimage from "../../assets/noimage.jpg"
import { ToastContainer, toast } from 'react-toastify';

function Setting() {
    let navigate = useNavigate()
    const [sitesettingDetails, setSitesettingDetails] = useState({
        email: null,
        phone: null,
        address: null,
        logo: null,
        level_commission_1: 0,
        level_commission_2: 0,
        minimum_withdraw_amount: 0,
        logo_path: null
    })
    const [logoUpload, setLogoupload] = useState("")
    const uploadlogo = (e) => {
        // console.log(e.target.files[0].name,'mfiddfs')
        setLogoupload(e.target.files)
        // setSitesettingDetails()
        setSitesettingDetails(prevData => ({
            ...prevData, // Copy existing state
            logo_path: URL.createObjectURL(e.target.files[0]) // Update the specific field
        }));

    }
    useEffect(() => {
        getsitesettingDetails()
    }, [])

    function submitData() {
        const formData = new FormData();
        formData.append('email', sitesettingDetails?.email);
        formData.append('phone', sitesettingDetails?.phone);
        formData.append('address', sitesettingDetails?.address);
        formData.append('minimum_withdraw_amount', sitesettingDetails?.minimum_withdraw_amount)
        formData.append('level_commission_1', sitesettingDetails?.level_commission_1);
        formData.append('level_commission_2', sitesettingDetails?.level_commission_2);
        formData.append('logo', logoUpload[0]);

        console.log(sitesettingDetails.level_commission_1)
        try {
            const result = httpClient.post("/admin/site-settings/update", formData, {

                headers: {
                    "Content-Type": "multipart/form-data",
                    "Accept": "multipart/form-data",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }

            }).then((res) => {
                if (res.status === 200) {
                    toast.success(res?.data?.message)
                } else if (res.status !== 200) {
                    toast.error(res?.data?.message)
                }
            });



        }
        catch (error) {
            toast.error(error.data.message)

        }
    }
    const getsitesettingDetails = async () => {
        try {
            const result = await httpClient.post('/admin/site-settings', {}, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            if (result.status === 200) {
                setSitesettingDetails(result?.data?.data)
            }

        } catch (error) {
            //   toast.error(error.message)
        }
    }
    return (
        <>
            <ToastContainer
                autoClose={1000}
            />
            <div className="setting-heading">
                <div></div>
                <div
                    onClick={() => navigate('/change-password')}
                    className="change-password-box">
                    <p className="changepassword-text">Change Password</p>
                </div>

            </div>
            <div className="row">
                {/* <div className="card col-md-12"> */}
                <div className="col-md-4">
                    <div className="formGroup">
                        <label>Email *</label>
                        <input type="text"
                            value={sitesettingDetails?.email}
                            placeholder="Enter Email"
                            onChange={(val) => {
                                setSitesettingDetails(prevData => ({
                                    ...prevData, // Copy existing state
                                    email: val.target.value // Update the specific field
                                }));

                            }}

                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="formGroup">
                        <label>Phone *</label>
                        <input type="number"
                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            value={sitesettingDetails?.phone}
                            placeholder="Enter Phone Number"
                            maxLength={10}
                            onChange={(val) => {
                                setSitesettingDetails(prevData => ({
                                    ...prevData, // Copy existing state
                                    phone: val.target.value // Update the specific field
                                }));

                            }}

                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="formGroup">
                        <label>Address</label>
                        <input type="text"
                            value={sitesettingDetails?.address}
                            placeholder="Enter Address"
                            onChange={(val) => {
                                setSitesettingDetails(prevData => ({
                                    ...prevData, // Copy existing state
                                    address: val.target.value // Update the specific field
                                }));

                            }}

                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="formGroup">
                        <label>Level 1 Commission (%)</label>
                        <input type="number"
                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            value={sitesettingDetails?.level_commission_1}
                            placeholder="Enter Level 1 Commission (%)"
                            onChange={(val) => {
                                setSitesettingDetails(prevData => ({
                                    ...prevData, // Copy existing state

                                    level_commission_1: val.target.value // Update the specific field
                                }));

                            }}

                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="formGroup">
                        <label>Level 2 Commission (%)</label>
                        <input type="number"
                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            value={sitesettingDetails?.level_commission_2}
                            placeholder="Enter Level 2 Commission (%)"
                            onChange={(val) => {

                                setSitesettingDetails(prevData => ({
                                    ...prevData, // Copy existing state
                                    level_commission_2: val.target.value // Update the specific field
                                }));
                            }}
                            // min={1}
                            // max={80}
                            required
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="formGroup">
                        <label>Minimum Widthdraw Amount</label>
                        <input type="number"
                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            value={sitesettingDetails?.minimum_withdraw_amount}
                            placeholder="Enter Level 2 Commission"
                            onChange={(val) => {
                                setSitesettingDetails(prevData => ({
                                    ...prevData, // Copy existing state
                                    minimum_withdraw_amount: val.target.value // Update the specific field
                                }));
                            }}

                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="formGroup">
                        <label>Upload Logo</label>
                        <input type="file"
                            // value={chooseplaceholder}
                            placeholder="Upload Logo"
                            onChange={(e) => { uploadlogo(e) }}

                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <label>Logo</label>
                    <div className="acc-details-image">
                        <div className="image-container">
                            <img
                                className="image-acc-details"
                                src={sitesettingDetails?.logo_path !== null ?

                                    sitesettingDetails?.logo_path : noimage
                                }></img>
                        </div>
                    </div>

                </div>
                <div className="col-md-4">
                    <div className="formGroup">
                        <label>&nbsp;</label>
                        <button
                            onClick={() => submitData()}

                            disabled={(sitesettingDetails?.email === '' || sitesettingDetails?.phone === '') ? true : false}
                            type="button" className="addBtn">Submit</button>
                    </div>

                </div>
                {/* <h6>Site Setting Details</h6>
                  <div>
                    <p>Email : {sitesettingDetails?.email}</p>
                    <p>Phone : {sitesettingDetails?.phone}</p>
                    <p>Level 1 Commission : {sitesettingDetails?.level_commission_1}</p>
                    <p>Level 2 Commission : {sitesettingDetails?.level_commission_2}</p>

                  </div> */}
                {/* </div> */}

            </div>

        </>


    )
}
export default Setting