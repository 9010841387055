import { useEffect, useMemo } from "react";
import httpClient from "../../HTTPService";
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import React, { useRef } from 'react';
import Loding from "../../Component/Loding/Loding";
import { json, useLocation, useNavigate } from "react-router-dom";
const FormManagement = () => {
    const [chooseFldType, setchooseFldType] = useState('')
    const [choosetype, setchoosetype] = useState(null)
    const [labelvalue, setLabel] = useState()
    const navigate = useNavigate()
    const [fileSelected, setFileSelected] = useState(false)
    const [chooseName, setchooseName] = useState()
    const [optionValue, setOptions] = useState()
    const [labelList, setLabelList] = useState([])
    const [chooseplaceholder, setChooseplaceholder] = useState("")
    const [chooseRequired, setChooseRequired] = useState(1)
    const [ishide, setIshide] = useState(0)
    const [getCategory, setgetCategory] = useState();
    const [getSubCategory, setgetSubCategory] = useState(null)
    const [selectSub, setectSub] = useState();
    const [subCat, setSubcatory] = useState();
    const [dynamicFld, setdynamicFld] = useState([]);
    const [optionList, setoptionList] = useState([]);
    const [lebelnmaedata, setleblenamedata] = useState()
    const [loadingState, setLoadingState] = useState(false)
    const [labelselectedStatic, setLabelselectedStatic] = useState(0)
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search); // Parse query string
    const id = queryParams.get('id');
    const [checkChostype, setChosetype] = useState(false)
    const labeltype_data = [
        {
            id: 1,
            name: "Full Name",
            icon: 'user',
            value: 'name',
            icon_type: 'Feather'

        },
        {
            id: 3,
            name: "Email Address",
            value: 'email_address',
            icon: 'mail',
            icon_type: 'Feather'

        },
        {
            id: 4,
            name: "Phone",
            icon: 'phone',
            value: 'phone',

            icon_type: 'Feather'

        },

        {
            id: 5,
            name: "Date of Birth",
            icon: 'calender',
            value: 'calender',
            icon_type: 'Feather'

        },

        {
            id: 6,
            name: "Address",
            icon: 'home',
            value: 'address',
            icon_type: 'Feather'

        },
        {
            id: 7,
            name: "Apartment/Suite/Unit",
            icon: 'home',
            value: 'apartment',
            icon_type: 'Feather'

        },
        {
            id: 8,
            name: "City",
            icon: 'city',
            value: 'city',
            icon_type: 'FontAwesome5'

        },

        {
            id: 9,
            name: "PIN Code",
            icon: 'location-pin',
            value: 'pin',
            icon_type: 'Entypo'

        },

        {
            id: 10,
            name: "Company Name",
            icon: 'building-o',
            value: 'company_name',
            icon_type: 'FontAwesome'

        },
        {
            id: 11,
            name: "Job Title",
            icon: 'contacts',
            value: 'job_title',
            icon_type: 'AntDesign'

        },
        {
            id: 12,
            name: "Department",
            icon: 'building-o',
            value: 'department',
            icon_type: 'FontAwesome'

        },
        // {
        //     id: 16,
        //     name: "Comments",
        //     icon: 'message1',
        //     value: 'comments',
        //     icon_type: 'AntDesign'

        // },
        // {
        //     id: 17,
        //     name: "Suggestions",
        //     icon: 'settings-suggest',
        //     value: 'suggestions',
        //     icon_type: 'MaterialIcons'

        // },
        // {
        //     id: 18,
        //     name: "Feedback",
        //     icon: 'feed-discussion',
        //     value: 'feed_back',
        //     icon_type: 'Octicons'

        // },

        // {
        //     id: 20,
        //     name: "Review",
        //     icon: 'staro',
        //     value: 'review',

        //     icon_type: 'AntDesign'

        // },
        {
            id: 13,
            name: "School/University Name",
            icon: 'school',
            value: 'school',

            icon_type: 'FontAwesome5'

        },
        {
            id: 14,
            name: "Degree",
            icon: 'school',
            value: 'degree',
            icon_type: 'FontAwesome5'

        },
        {
            id: 15,
            name: "Major",
            icon: 'school',
            value: 'major',
            icon_type: 'FontAwesome5'

        },
        {
            id: 16,
            name: "Graduation Year",
            icon: 'calendar',
            value: 'graduation_year',

            icon_type: 'AntDesign'

        },
        {
            id: 17,
            name: "Employer Name",
            icon: 'user',
            value: 'employer_name',

            icon_type: 'Feather'

        },
        {
            id: 18,
            name: "Job Title",
            icon: 'user',
            value: 'job_title',

            icon_type: 'Feather'
        },
        {
            id: 19,
            name: "Employment Start Date",
            icon: 'calendar',
            value: 'employment_start_date',

            icon_type: 'AntDesign'

        },
        {
            id: 20,
            name: "Employment End Date",
            icon: 'calendar',
            value: 'employment_end_date',
            icon_type: 'AntDesign'

        },
        // {
        //     id: 28,
        //     name: "Query",
        //     icon: 'calendar',
        //     value: 'query',

        //     icon_type: 'AntDesign'

        // },
        {
            id: 21,
            name: "Description",
            icon: 'calendar',
            value: 'description',

            icon_type: 'AntDesign'

        },
        {
            id: 22,
            name: "Notes",
            icon: 'calendar',
            value: 'notes',

            icon_type: 'AntDesign'

        },
        {
            id: 23,
            name: "Additional Information",
            value: 'additional_information',
            icon: 'calendar',
            icon_type: 'AntDesign'

        },
        {
            id: 24,
            name: "WhatsApp",
            value: 'whatsapp',
            icon: 'whatsapp',
            icon_type: 'FontAwesome'

        },
        {
            id: 25,
            name: "Community",
            value: 'community',
            icon: 'addusergroup',
            icon_type: 'AntDesign'

        },
        {
            id: 26,
            name: "Occupation",
            value: 'occupation',
            icon: 'briefcase',
            icon_type: 'FontAwesome'

        },
        {
            id: 27,
            name: "House No/Street/Road",
            value: 'house_no_street_road',
            icon: 'house-user',
            icon_type: 'FontAwesome5'

        },
        {
            id: 28,
            name: "Area/Local/Sector",
            value: 'area_local_sector',
            icon: 'road',
            icon_type: 'FontAwesome'

        },
        {
            id: 29,
            name: "Village/City/Town",
            value: 'village_city_town',
            icon: 'city',
            icon_type: 'FontAwesome5'

        },
        {
            id: 29,
            name: "Post Office",
            value: 'post_office',
            icon: 'school',
            icon_type: 'FontAwesome5'

        },



    ]
    const [isrequired, setisrequired] = useState(false)
    useEffect(() => {
        getcatname();
    }, []);

    useEffect(() => {
        getlabelList()
    }, [])
    // Get Option List
    const getlabelList = async () => {
        try {
            const result = await httpClient.post("/admin/all-input-labels", {}, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            setLabelList(result.data.data)
            // console.log(result.data);
        }
        catch (error) {

        }
    }
    useEffect(() => {
        if (getSubCategory) {
            getSub(getSubCategory);
        }
    }, [getSubCategory])

    useMemo(() => {
        let _checkData = [...dynamicFld].some(obj => obj['name'] === 'file')
        if (_checkData) {
            setFileSelected(true)
        } else {
            setFileSelected(false)
        }
    }, [dynamicFld])

    // get sub cat
    useEffect(() => {
        // console.log(chooseFldType)
        const _final_data = {
            name: chooseFldType,
            value: chooseFldType,

        }
        if (chooseFldType === 'file' || chooseFldType === 'date' || chooseFldType === 'time') {
            setLabel(chooseFldType)
            console.log(_final_data)
            // setleblenamedata(_final_data)
        }
        // console.log('field type.....')
        setleblenamedata(_final_data)
        // console.log(chooseFldType)
    }, [chooseFldType])
    useEffect(() => {
        if (labelvalue != 'file' && labelvalue != 'time' && labelvalue != 'date') {
            let fil_data = labelList?.find(item => {
                if (item.id == labelvalue) {
                    let returnObj = {
                        name: item?.name,
                        value: item?.value,

                    }
                    return returnObj
                } else {
                    return null
                }
            })
            console.log(fil_data, 'seke')
            setleblenamedata(fil_data)
        }
    }, [labelvalue])
    useEffect(() => {
        if (choosetype !== null) {

            let choose_data = optionList?.find(item => item?.id == choosetype)
            const _final_data = {
                name: choose_data?.name,
                value: choose_data?.name,

            }
            setleblenamedata(_final_data)
        }

    }, [choosetype])

    function ondeleteItems(indexs) {
        let _modifiedarray = dynamicFld?.filter((obj, index) => index !== indexs)
        setdynamicFld(_modifiedarray)
    }

    useEffect(() => {
        if (id != undefined) {
            getformDetails()
        }
    }, [id])

    const getformDetails = async (category_id) => {
        // setLoadingState(true)
        try {
            const result = await httpClient.post("/admin/form/view", JSON.stringify({ id: id }), {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then((res) => {
                setSubcatory(res.data.data?.sub_category_id)
                setgetSubCategory(res.data.data?.category_id)
                let _actualdata = JSON.parse(res.data.data?.form_data)
                console.log(_actualdata, 'hh')
                let _data = _actualdata?.map((item) => {
                    let data = {
                        type: item.type,
                        select_box_type: item.type,
                        label: item.label,
                        name: item.name,
                        place_holder: item.place_holder,
                        is_required: parseInt(item?.is_required),
                        value: item?.value,
                        option: item?.option != undefined ? item?.option : 0,
                        is_field_show: parseInt(item?.is_field_show)

                    }
                    return data
                })
                setdynamicFld(_data)
            })

            // setLoadingState(false)

        }

        catch (error) {
            toast.error(error.message)
        }
    }

    const getSub = async (category_id) => {
        // setLoadingState(true)
        try {
            const result = await httpClient.post("/admin/all-sub-categories-list", { category_id }, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            setectSub(result.data.data)
            // setLoadingState(false)

        }

        catch (error) {
            toast.error(error.message)
        }
    }
    // add From 
    const addFld = async (event) => {

        if(fileSelected) {
            toast.error('Allready File Selected')
            event.preventDefault();
            return
        } 
        
        // else {
            if (labelvalue === null || chooseFldType === null || lebelnmaedata?.name === null) {
                setisrequired(true)
                return
            }
            event.preventDefault();

            setdynamicFld(prevOptionData => {

                if (prevOptionData.length > 0) {
                    return [...prevOptionData, {
                        type: chooseFldType,
                        select_box_type: choosetype,
                        label: lebelnmaedata?.name,
                        name: lebelnmaedata?.value,
                        place_holder: chooseplaceholder?.charAt(0).toUpperCase() + chooseplaceholder?.slice(1),
                        is_required: parseInt(chooseRequired),
                        option: choosetype,
                        value: "",
                        is_field_show: parseInt(ishide)

                    }];
                } else {
                    return [{
                        type: chooseFldType,
                        select_box_type: choosetype,
                        label: lebelnmaedata?.name?.charAt(0).toUpperCase() + lebelnmaedata?.name?.slice(1),
                        name: lebelnmaedata?.value?.charAt(0).toLowerCase() + lebelnmaedata?.value?.slice(1),
                        place_holder: chooseplaceholder?.charAt(0).toUpperCase() + chooseplaceholder?.slice(1),
                        is_required: parseInt(chooseRequired), option: choosetype, value: "",
                        option: choosetype,
                        value: "",
                        is_field_show: parseInt(ishide)

                    }];
                }
            });
            setLabel(0)
            setchooseFldType('')
            setchoosetype(null)
            setleblenamedata(null)
            setChooseplaceholder('')
            // setChooseRequired('')
            document.getElementById('resetForm').reset()
        // }

        // else{
        //     console.log('no selelcte')



    }
    const FrmCreation = async () => {
        
        if (id != undefined) {
            let body = {
                category_id: getSubCategory,
                sub_category_id: subCat,
                id: id,
                form_data: dynamicFld
            }

            console.log(body, 'update data')
            try {
                const result = await httpClient.post("/admin/form/update", JSON.stringify(body), {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                })
                if (result.data.status) {
                    // localStorage.removeItem('editSubData')
                    // document.getElementById('addForm').reset();
                    toast.success(result.data.message);
                    setTimeout(() => {
                        navigate('/formlist')
                    }, 1000)

                }


                if (result.data.status == false) {
                    toast.error(result.data.message)
                }


            }
            catch (error) {
                toast.error(error.message)
            }

        } else {

            try {
                const result = await httpClient.post("/admin/form/create", { category_id: getSubCategory, sub_category_id: subCat, form_data: dynamicFld }, {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }).then((res) => {
                    if (res.status === 200) {
                        console.log(res.data, 'RESPONSE MDATATA..')
                        toast.success("Form Creation Successfully!")
                        navigate('/formlist')
                    } else {
                        // if (result.data.status == false) {
                        toast.error(result.data.message)
                        // }
                    }
                })

            }

            catch (error) {
                toast.error(error.message)
            }
        }

    }
    const setfldType = async (val) => {
        // setLoadingState(true)
        setchooseFldType(val)
        if (val == "select") {
            try {
                const result = await httpClient.post("/admin/option-masters-list", { ofset: 1, limit: 100 }, {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                })

                setoptionList(result.data.data)
                // setLoadingState(false)

            }
            catch (error) {

            }
        } else {
            setoptionList([])
        }
    }
    const back = () => {
        navigate('/formlist')

    }


    const getcatname = async () => {
        try {
            const result = await httpClient.post("/admin/all-categories-list", {}, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            setgetCategory(result.data.data);

        }
        catch (error) {

        }
    }
    return (
        <div className="formManagement">
            <ToastContainer
                autoClose={1000}
            />
            <div className="card">
                {
                    loadingState ? <Loding /> : null
                }

                <div className="row">
                    <div className="col-md-4">
                        <div className="formGroup">
                            <label>Parent Category *</label>
                            <select value={getSubCategory}
                                className="empty-field"
                                required
                                onChange={(val) => { setgetSubCategory(val.target.value) }}>
                                <option disabled>Select Category</option>
                                {
                                    getCategory?.map((item, i) => {
                                        return <option key={i} value={item.id}>{item.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="formGroup ">
                            <label>Select Sub Category *</label>
                            <select value={subCat}
                                required
                                onChange={(val) => { setSubcatory(val.target.value) }}
                            // className="empty-field"
                            >
                                <option>Choose Sub Category</option>
                                {
                                    selectSub?.map((item, i) => {
                                        return <option key={i} value={item?.id}>{item?.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <form onSubmit={addFld} id="resetForm">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Form Creation </h2>
                        </div>

                        <div className="col-md-3">
                            <div className="formGroup">
                                <label>Select Input Type *</label>

                                <select
                                    onChange={(val) => { setfldType(val.target.value) }}

                                // className={(isrequired || chooseFldType == null) ? 'required' :'' }

                                >
                                    <option>Select </option>
                                    <option value="text">Text Box</option>
                                    <option value="select">Select Box</option>
                                    <option value="textarea">Textarea</option>
                                    <option value="date">Date</option>
                                    {/* <option value="dob">DOB</option> */}
                                    <option value="time">Time</option>
                                    <option value="file">File</option>
                                </select>
                            </div>
                        </div>
                        {optionList?.length > 0 ?
                            <div className="col-md-3">
                                <div className="formGroup">
                                    <label>Choose Type *</label>
                                    <select
                                        //   className='required'
                                        value={choosetype} onChange={(val) => { setchoosetype(val.target.value) }}>
                                        <option selected value={0}>Choose Select Box</option>
                                        {
                                            optionList?.map((item, i) => {
                                                return <option
                                                    // onSelect={(item) => console.log(item)}
                                                    value={item.id} key={i}>{item.name}</option>
                                            })
                                        }

                                    </select>
                                </div>
                            </div> : ""
                        }
                        {
                            // chooseFldType !== 'date' && chooseFldType !== 'time' && chooseFldType !== 'fiile' ?

                            <div className="col-md-3">
                                <div className="formGroup">
                                    <label>Choose Label *</label>
                                    {
                                        (chooseFldType === 'select'
                                            || chooseFldType === 'date'
                                            || chooseFldType === 'file'
                                            || chooseFldType === 'time'
                                            // || chooseFldType ==='dob'
                                        )
                                            ?
                                            <div>
                                                <select
                                                    disabled={chooseFldType == 'select' ? true : false}
                                                    value={lebelnmaedata?.name}
                                                >
                                                    {/* <option value={0} key={0}>Label Name</option> */}

                                                    <option value={0} key={0}>{lebelnmaedata?.name}</option>
                                                </select>
                                            </div>
                                            :

                                            <select value={labelvalue}
                                                disabled={chooseFldType === '' ? true : false}
                                                onChange={(val) => { setLabel(val.target.value) }}
                                            //   className={(isrequired && labelvalue === null) ? 'required' :'' }
                                            >

                                                {/* { */}
                                                {/* choosetype !== null ?
                                                <option value={lebelnmaedata?.name} key={1}>{ lebelnmaedata?.name}</option>
                                                :
                                                <div> */}

                                                <option selected value={0}>Choose Label</option>
                                                {
                                                    labelList?.map((item, i) => {
                                                        return <option value={item.id} key={i}>{item.name}</option>
                                                    })
                                                }
                                                {/* </div> */}
                                                {/* } */}

                                            </select>
                                    }
                                </div>
                            </div>
                            // : null
                        }
                        <div className="col-md-3">
                            <div className="formGroup">
                                <label>Field Name</label>
                                <input type="text"
                                    disabled={true}
                                    value={lebelnmaedata?.name}
                                    placeholder="Field Value" />
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="formGroup">
                                <label>Placeholder *</label>
                                <input type="text" value={chooseplaceholder} placeholder="Enter Placeholder Name"
                                    onChange={(val) => { setChooseplaceholder(val.target.value) }}

                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="formGroup">
                                <label>Required *</label>
                                <select
                                    //   className='required'
                                    value={chooseRequired} onChange={(val) => { setChooseRequired(val.target.value) }}>
                                    {/* <option  value={0}>Select Required Type</option>
                                <option value={1}>Yes</option>
                                <option value={0}>No</option> */}
                                    {/* <option>Select Required Type</option> */}
                                    <option value={1}>Mandatory</option>
                                    <option value={0}>Optional</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="formGroup">
                                <label>Hide*</label>
                                <select
                                    //   className='required'
                                    value={ishide} onChange={(val) => { setIshide(val.target.value) }}>
                                    {/* <option  value={0}>Select Required Type</option>
                                <option value={1}>Yes</option>
                                <option value={0}>No</option> */}
                                    {/* <option>Select Required Type</option> */}
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="formGroup">
                                <label>&nbsp;</label>
                                <button
                                    disabled={
                                        (
                                            ((subCat == null || getSubCategory == null ||
                                                chooseplaceholder === '' || chooseFldType === '') || (choosetype == null && labelvalue == null))
                                            // choosetype == null
                                            // lebelnmaedata == null
                                            // lebelnmaedata == null

                                        )
                                            ? true : false
                                    }
                                    type="submit" className="addBtn">Add</button>
                            </div>

                        </div>
                    </div>
                </form>
                <form>
                    <div className="row" id="addDynamicFrm">
                        {
                            dynamicFld?.map((item, index) => {
                                if (item.type === "text") {

                                    // console.log("ok")
                                    return <div className="col-md-4" key={index}>
                                        <div className="formGroup">
                                            <label className="name-width">{item.label}</label>
                                            <div className="item-list">
                                                <input type={item.type} placeholder={item.place_holder} name={item.name} />
                                                <div className="cross-icon" onClick={() => ondeleteItems(index)}>
                                                    <div className="">
                                                        <p className="icon">X</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                if (item.type == "select") {
                                    return <div className="col-md-4" key={index}>
                                        <div className="formGroup">
                                            <label>{item.label}</label>
                                            <div className="item-list">
                                                <select>
                                                    <option selected>{item.place_holder}</option>
                                                    {/* <option></option> */}
                                                </select>
                                                <div className="cross-icon" onClick={() => ondeleteItems(index)}>
                                                    <div className="">
                                                        <p className="icon">X</p>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                }
                                if (item.type == "textarea") {
                                    return <div className="col-md-4" key={index}>
                                        <div className="formGroup">
                                            <label>{item.label}</label>
                                            <div className="item-list">
                                                <textarea name={item.name} placeholder={item.place_holder}></textarea>
                                                <div className="cross-icon" onClick={() => ondeleteItems(index)}>
                                                    <div className="">
                                                        <p className="icon">X</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                if (item.type == "date") {
                                    return <div className="col-md-4" key={index}>
                                        <div className="formGroup">
                                            <label>{item.label}</label>
                                            <div className="item-list">
                                                <input type={item.type} placeholder={item.place_holder} name={item.name} />
                                                <div className="cross-icon" onClick={() => ondeleteItems(index)}>
                                                    <div className="">
                                                        <p className="icon">X</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                // if (item.type == "dob") {
                                //     return <div className="col-md-4" key={index}>
                                //         <div className="formGroup">
                                //             <label>{item.label}</label>
                                //             <div className="item-list">
                                //                 <input type='date' placeholder={item.place_holder} name={item.name} />
                                //                 <div className="cross-icon" onClick={() => ondeleteItems(index)}>
                                //                     <div className="">
                                //                         <p className="icon">X</p>

                                //                     </div>
                                //                 </div>
                                //             </div>
                                //         </div>
                                //     </div>
                                // }
                                if (item.type == "time") {
                                    return <div className="col-md-4" key={index}>
                                        <div className="formGroup">
                                            <label>{item.label}</label>
                                            <div className="item-list">
                                                <input type={item.type} placeholder={item.place_holder} name={item.name} />
                                                <div className="cross-icon" onClick={() => ondeleteItems(index)}>
                                                    <div className="">
                                                        <p className="icon">X</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                if (item.type == "file") {
                                    return <div className="col-md-4" key={index}>
                                        <div className="formGroup">
                                            <label>{item.label}</label>
                                            <div className="item-list">
                                                <input type={item.type} name={item.name} />
                                                <div className="cross-icon" onClick={() => ondeleteItems(index)}>
                                                    <div className="">
                                                        <p className="icon">X</p>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                }

                            })

                        }
                    </div>

                </form>
                <div className="row">
                    <div className="col-md-12">
                        <div className="btngrp">
                            <button type="button"
                                disabled={dynamicFld?.length != 0 ? false : true}

                                style={{ marginLeft: "0px" }} className="addBtn" onClick={FrmCreation}>{id != undefined ? 'Update' : 'Save'}</button>
                            <button type="button" className="addBtn " onClick={back}>Cancel</button>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}
export default FormManagement;

