import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify"
import httpClient from "../../HTTPService";
import { useLocation, useNavigate } from "react-router-dom";

function FormLabelmaster() {
    const [name, setName] = useState('')
    const [value, setValue] = useState('')
    const location = useLocation()
    const navigate = useNavigate()
    // const [invalid, setinvalid] = useState(false)
    // const [form]
    const queryParams = new URLSearchParams(location.search); // Parse query string
    const id = queryParams.get('id');

    useEffect(() => {
        if (id != undefined) {
            getlabeDetails()
        }
    }, [id])
    // get details
    const getlabeDetails = async () => {
        try {
            const result = await httpClient.post("/admin/input-labels/view", JSON.stringify({ id: id }), {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            setName(result?.data?.data?.name)
            setValue(result?.data?.data?.value)
            // console.log(result.data);
        }
        catch (error) {

        }
    }
    async function SubmitLabel() {
        if (id != undefined) {
            const formData = new FormData();
            formData.append('id', id)
            formData.append('name', name);
            formData.append('value', value);

            try {
                const result = await httpClient.post("/admin/input-labels/update", formData, {

                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Accept": "multipart/form-data",
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }

                });
                if (result.data.status == true) {
                    toast.success(result.data.message)
                    setTimeout(() => {
                        navigate('/labelmaster')

                    }, 1000);
                } else if (result.data.status == false) {
                    toast.error(result.data.message)
                }
            } catch {

            }
        } else {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('value', value);

            try {
                const result = await httpClient.post("/admin/input-labels/create", formData, {

                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Accept": "multipart/form-data",
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }

                });
                if (result.data.status == true) {
                    toast.success(result.data.message)

                } else if (result.data.status == false) {
                    toast.error(result.data.message)
                }
            } catch {

            }
        }

    }

    return (
        <>
            <div className="AddEditOption">
                <ToastContainer
                    autoClose={1000}
                />
                <div className="card">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formGroup">
                                <label>Label Name</label>
                                <input
                                    onKeyDown={(evt) => ["@", "#", "$", "%", "&"].includes(evt.key) && evt.preventDefault()}
                                    type="text"
                                    name="optionName"
                                    placeholder="Name"
                                    required
                                    value={name}
                                    onChange={(val) => {
                                        setName(val.target.value)
                                        let _data = val?.target?.value?.split(/[\s\/]+/).filter(part => part.trim() !== '')
                                            .map(part => part.charAt(0).toLowerCase() + part.slice(1))
                                            .join('_');
                                        console.log(_data, val.target.value)
                                        setValue(_data)

                                    }}

                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="btngrp">
                                <button type="button"
                                    disabled={name?.length != 0 ? false : true}
                                    onClick={() => SubmitLabel()}
                                    style={{ marginLeft: "0px" }} className="addBtn">{id != undefined ? 'Update' : 'Save'} </button>
                                <button type="button" onClick={() => navigate('/labelmaster')} className="addBtn ">Cancel</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default FormLabelmaster