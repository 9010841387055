
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";
import httpClient from "../../HTTPService";
import { ToastContainer, toast } from 'react-toastify';
import noImage from "../../assets/noimage.jpg";
import Loding from '../../Component/Loding/Loding';

const AddEditCategory = () => {
    const navigate = useNavigate();
    const location = useLocation()
    // const {id} = useParams()
    const { state } = location
    const [categotyName, setCategotyName] = useState("");
    const [catStatus, setStatus] = useState(0);
    const [catImage, setcatImage] = useState("");
    const [catImageIcon, setcatImageIcon] = useState("");
    const [catImageIconprv, setcatImageIconPrv] = useState("");
    const [imagePrvw, setimagePrvw] = useState("");
    const [itemId, setitemId] = useState('');
    const [editMode, seteditMode] = useState(false);
    const [isSubmitting, setisSubmitting] = useState(false)
    const [detailsData, setDetailsData] = useState({})
    const queryParams = new URLSearchParams(location.search); // Parse query string
    const id = queryParams.get('id');
    const [loadingstate, setloadinigstate] = useState(false)
    useEffect(() => {
        if (id != undefined) {
            setloadinigstate(true)
            httpClient.post("/admin/categories/view", {
                "id": id
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then((res) => {
                // console.log(res.data, 'kkj')
                setimagePrvw(res?.data?.data?.image_path)
                setcatImageIconPrv(res?.data?.data?.icon_path)
                setCategotyName(res?.data?.data?.name)
                setStatus(res?.data?.data?.active)
                setloadinigstate(false)
                //  setDetailsData(res.data)
            })

        }

        // console.log(result ,'category details')
    }, [id])

    const uploadcatimage = (e) => {
        setcatImageIcon(e.target.files);
        console.log(e.target.files, 'mfiddfs')
        // console.lo
        setcatImageIconPrv(URL.createObjectURL(e.target.files[0]))
    }
    // upload Image
    const uploadcat = (e) => {
        // console.log(e.target.files[0].name,'mfiddfs')
        setcatImage(e.target.files)
        setimagePrvw(URL.createObjectURL(e.target.files[0]))

    }
    const addCatform = async (event) => {
        event.preventDefault();
        // if (isSubmitting) return;
        // setisSubmitting(true);
        if (id != undefined) {
            const numberValue = parseInt(id)
            console.log(numberValue, 'number value')
            const formData = new FormData();
            formData.append('id', parseInt(id));
            formData.append('name', categotyName);
            formData.append('active', catStatus);
            formData.append('image', catImage[0]);
            formData.append('icon', catImageIcon[0]);
            // console.log(Object.fromEntries(formData, 'form data..'))
            try {
                const result = await httpClient.post("/admin/categories/update", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Accept": "multipart/form-data",
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                })
                if (result.status === 200) {

                    toast.success(result.data.message);
                    setTimeout(() => {
                        navigate('/category')
                    }, 1000)

                }


                if (result.data.status == false) {
                    toast.error(result.data.message)
                }


            }
            catch (error) {
                toast.error(error.message)
            }
        }
        else {
            const formData = new FormData();
            formData.append('name', categotyName);
            formData.append('active', catStatus);
            formData.append('image', catImage[0]);
            formData.append('icon', catImageIcon[0]);
            console.log(Object.fromEntries(formData))
            // if (isSubmitting) return; // Prevent multiple clicks
            // setisSubmitting(true);
            try {
                const result = await httpClient.post("/admin/categories/create", formData, {

                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Accept": "multipart/form-data",
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }

                });
                if (result.data.status == true) {
                    toast.success(result.data.message)
                    setTimeout(() => {
                        navigate('/category')
                    }, 1000)
                }
                if (result.data.status == false) {
                    toast.error(result.data.message)

                }


            }
            catch (error) {
                toast.error(error.message)

            } finally {
                // setisSubmitting(false)
            }
        }



    }

    return (
        <div className="cataddedit">
            <ToastContainer 
            autoClose={1000}
            />
            {
                loadingstate ?
                    <Loding />
                    : null

            }
            <div className="card">
                <form onSubmit={addCatform} id="addForm">
                    <div className="row">
                        <div className="col-md-3">

                            <div className="formGroup flxfrm">
                                <div className="catIcon">
                                    <img src={imagePrvw  ? imagePrvw : noImage} 
                                    />
                                </div>
                                <span className="uploadImage" onChange={(e) => { uploadcat(e) }}>
                                    <input type="file" name="image" />
                                    <p className="uploade-text">Upload Image</p>
                                    <div className="upload-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708z" />
                                            <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383m.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                                        </svg>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="formGroup flxfrm">
                                <div className="catIcon">
                                    <img src={catImageIconprv? catImageIconprv : noImage} />
                                </div>

                                <span className="uploadImage" onChange={(e) => { uploadcatimage(e) }}>

                                    <input type="file" name="image" />
                                    <p className="uploade-text">Upload Icon</p>
                                    <div className="upload-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708z" />
                                            <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383m.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                                        </svg>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="formGroup">
                                <label>Category Name</label>
                                <input type="text" name="categotyName" placeholder="Name" onChange={(val) => setCategotyName(val.target.value)} value={categotyName} required />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="formGroup">
                                <label>Status</label>
                                <select value={catStatus} onChange={(val) => setStatus(val.target.value)} >
                                    <option>Select Status</option>
                                    <option value={1}>Active</option>
                                    <option value={0}>Inactive</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="btngrp">
                                <button type="button" className="themeBtn cancelBtn" onClick={() => { navigate('/category'); localStorage.removeItem('editData') }}>Cancel</button>
                                <button type="submit" className="themeBtn" disabled={isSubmitting}>{id != undefined ? "Update" : "Submit"}</button>
                            </div>

                        </div>
                    </div>

                </form>

            </div>
        </div>
    )
}
export default AddEditCategory;