import { useEffect, useState } from "react";
// import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, } from 'react-accessible-accordion';
import { useLocation, useNavigate } from "react-router-dom";
import httpClient from "../../HTTPService";
import noimage from "../../assets/noimage.jpg"
import moment from "moment";
import Icon, { FontAwesome, Feather } from 'react-js-vector-icons';
import '../FormManagement/FormManagement.css'
// import {icon}
function MemberProfileView() {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search); // Parse query string
    const id = queryParams.get('id');
    const [accouuntDetaila, setaccountDetails] = useState()
    const [businessProfiles, setBusinessProfiles] = useState([])
    const [bank_details, setBankdetails] = useState()
    const [memberSubDetails, setmembersSubDetails] = useState(null)
    const [subscriptionList, setSubscriptionList] = useState([])
    const [profileSubsciption, setProfileSubsCription] = useState([])
    const navigate = useNavigate()
    useEffect(() => {
        getMembersDetails()
    }, [])
    // useEffect(() => {
    //     console.log(location, 'location')
    // }, [location])
    const getMembersDetails = async () => {
        try {
            const result = await httpClient.post('/admin/members-profle-view', { id: id }, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            if (result.status == 200) {
                console.log(result.data, 'data..')
                setmembersSubDetails(result.data?.data?.current_membership)
                setaccountDetails(result.data.data)
                setBusinessProfiles(result.data?.profiles)
                setBankdetails(result.data?.bank_accounts[0])
                setSubscriptionList(result?.data?.subscriptions)
                setProfileSubsCription(result?.data?.profile_view_subscriptions)
                // setmemberlist(result?.data?.data)
            }

        } catch (error) {
            //   toast.error(error.message)
        }
    }
    return (

        <>
            <div className="header-back"
                onClick={() => navigate('/member', {
                    state: {
                        backpagi: location?.state?.paginationState
                    }
                })}
            >
                <div></div>
                <div className="back-button">
                    <i class="fa fa-arrow-left back-arrow-button"
                        // className="back-arrow-button"
                        aria-hidden="true"></i>
                </div>

            </div>
            <div className="row">
                <div className="card col-md-6">
                    <h5>Profile Details</h5>
                    <div className="acc-profile-image-section ">
                        {/* <div> */}
                        <p>Profile Image :</p>
                        {/* </div> */}
                        <div className="acc-details-image">
                            <div className="image-container">
                                <img
                                    className="image-acc-details"
                                    src={accouuntDetaila?.profile_photo_url !== null ?

                                        accouuntDetaila?.profile_photo_url : noimage
                                    }></img>
                            </div>

                            <div>

                                <span className={accouuntDetaila?.active == 1 ? "active_profile" : "inactive_profile"}>{accouuntDetaila?.active == 1 ? "Active" : "Inactive"}</span>
                            </div>
                        </div>
                    </div>
                    <div className="account-details">
                        <p>Name : </p>
                        <p className="ml-2">{accouuntDetaila?.full_name}</p>
                    </div>

                    {/* <div className="account-details">
                        <p>Email : </p>
                        <p className="ml-2"> {accouuntDetaila?.email}</p>
                    </div> */}
                    <div className="account-details">
                        <p>Phone : </p>
                        <p className="ml-2">{accouuntDetaila?.phone}</p>
                    </div>
                    <div className="addres-account-details">
                        <p>Address:
                            {`${accouuntDetaila?.state !== null ? `${accouuntDetaila?.state?.name}` : ''}
                         ${accouuntDetaila?.district !== null ? `,${accouuntDetaila?.district?.district_name}` : ''} 
                         ${accouuntDetaila?.city !== null ? `,${accouuntDetaila?.city}` : ''}
                         ${accouuntDetaila?.pin_code !== null ? `,${accouuntDetaila?.pin_code}` : ''}`}
                        </p>


                    </div>
                    {
                        accouuntDetaila?.adrress_1 !== null ?
                            <div className="account-details">
                                <p>Addres 2 : </p>
                                <p className="ml-2">{accouuntDetaila?.adrress_1}</p>
                            </div>
                            :
                            null

                    }
                    {
                        accouuntDetaila?.adrress_1 !== null ?
                            <div className="account-details">
                                <p>Addres 2 : </p>
                                <p className="ml-2">{accouuntDetaila?.adrress_1}</p>
                            </div>
                            :
                            null

                    }



                    {/* <div className="account-details">
                        <p>PIN Code : </p>
                        <p>{accouuntDetaila?.pin_code}</p>
                    </div> */}
                    {/* <div className="account-details">
                        <p>DOB : </p>
                        <p className="ml-2">{accouuntDetaila?.dob}</p>
                    </div> */}



                    <div>
                    </div>
                </div>
                <div className="card  col-md-6">
                    <h5>Account Details & Others Details</h5>
                    <div className="account-details">
                        <p>Member Id : </p>
                        <p className="ml-2">{accouuntDetaila?.user_referral_code}</p>
                    </div>
                    <div className="account-details">
                        <p>Joining Date : </p>
                        <p className="ml-2">{accouuntDetaila?.joing_date !== null ?
                            moment(accouuntDetaila?.joing_date).format('DD-MM-YYYY') : null}</p>
                    </div>
                    <div className="account-details">
                        <p>Refered By : </p>
                        <p className="ml-2">{accouuntDetaila?.referral_code !== null ?
                            accouuntDetaila?.referral_code : null}</p>
                    </div>
                    <div className="account-details">
                        <p>Position : </p>
                        <p className="ml-2">{accouuntDetaila?.role_name !== null ?
                            accouuntDetaila?.role_name : null}</p>
                    </div>
                    <div className="account-details">
                        <p>Account Holder Name : </p>
                        <p className="ml-2">{bank_details?.account_holder_name !== null ?
                            bank_details?.account_holder_name : null}</p>
                    </div>
                    <div className="account-details">
                        <p>Bank Name : </p>
                        <p className="ml-2">{bank_details?.bank_name !== null ?
                            bank_details?.bank_name : null}</p>
                    </div>
                    {/* <div className="account-details">
                        <p>Branch Name : </p>
                        <p className="ml-2">{bank_details?.branch_name !== null ?
                            bank_details?.branch_name : null}</p>
                    </div> */}
                    <div className="account-details">
                        <p>Account Number : </p>
                        <p className="ml-2">{bank_details?.account_no !== null ?
                            bank_details?.account_no : null}</p>
                    </div>
                    <div className="account-details">
                        <p>IFSC Code : </p>
                        <p className="ml-2">{bank_details?.ifsc_code !== null ?
                            bank_details?.ifsc_code : null}</p>
                    </div>
                </div>

                <div className="card col-md-12" >

                    {/* <Accordion accessKey={1}>
                        <AccordionItem key={1}   >
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <h5>Add Subscription</h5>

                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel> */}
                    <div className="table-responsive">
                        <h5>Subscription</h5>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>SL NO</th>
                                    <th>Categoty</th>
                                    <th>Sub Categoty</th>
                                    <th>Buying Plan</th>
                                    <th>Pay Amount</th>
                                    <th>Buying Date</th>
                                    <th>Expire On</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    subscriptionList?.map((item, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.category}</td>
                                                    <td>{item?.sub_category}</td>
                                                    <td>{item?.subscription_name}</td>
                                                    <td>{item?.amount}</td>
                                                    <td>{moment(item?.date).format('DD-MM-YYYY')}</td>
                                                    <td>{moment(item?.expiry_date).format('DD-MM-YYYY')}</td>
                                                </tr>
                                            </>
                                        )
                                    })
                                }

                            </tbody>
                        </table>
                    </div>
                    {/* </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem key={2}>
                            <AccordionItemHeading>
                                <AccordionItemButton>

                                    <h5>Profile Subscription </h5>

                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel> */}
                    <div className="table-responsive profilesub-main">
                        <h5>My Membership  </h5>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>SL NO</th>
                                    <th>Categoty</th>
                                    <th>Sub Categoty</th>

                                    <th>Pay Amount</th>
                                    <th>Buying Date</th>


                                </tr>
                            </thead>
                            <tbody>

                                {
                                    profileSubsciption?.map((item, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.category}</td>
                                                    <td>{item?.sub_category}</td>

                                                    <td>{item?.amount}</td>
                                                    <td>{moment(item?.date).format('DD-MM-YYYY')}</td>

                                                </tr>
                                            </>
                                        )
                                    })
                                }

                            </tbody>

                        </table>
                        {
                            profileSubsciption?.length == 0 ?
                                <div className="nodata-found-div">
                                    <p>No Data Found</p>

                                </div>
                                :
                                null
                        }
                    </div>
                    {/* </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion> */}

                    <div>
                        {/* <h5>Subscription Details</h5> */}




                    </div>
                    {/* </AccordionItemPanel>
                    </AccordionItem> */}


                </div>
                <div className="card col-md-12">
                    <h5>Business Profile</h5>
                    <div className="row">


                        {
                            businessProfiles?.map((items, index) => {
                                return (
                                    <>
                                        <div className="col-md-3 business_profile m-2">
                                            <div className="business-profileitem-count">
                                                <p className="acc-details-color">{index + 1}</p>

                                            </div>
                                            <div className="bus-category">
                                                <p className="business-text">Category : {items?.category}</p>
                                                <p className="business-text">Sub-Category : {items?.sub_category}</p>
                                            </div>

                                            <div className="acc-d-field" >

                                                {
                                                    items?.field_data?.map((item) => {
                                                        return (
                                                            <>
                                                                {/* <div className="row "> */}
                                                                {/* <i  
                                                            class={`fa ${
                                                                item?.icon === 'user' ? 'fa-user' : 'fa-user'
                                                                
                                                                }`} aria-hidden="true"
                                                            
                                                            ></i> */}
                                                                <ul className="items-business-list">
                                                                    <li className="">
                                                                        {item?.field_value !== 'file' ? item?.field_value : 'Image'}
                                                                    </li>
                                                                </ul>

                                                                {/* </div> */}
                                                            </>
                                                        )

                                                    })

                                                }
                                                {/* <img src={items?.}/> */}
                                            </div>
                                            <div className="row business-images">

                                                {
                                                    items?.images?.image_path?.map((field) => {
                                                        return (
                                                            <div className="image-container">

                                                                <img className="image-acc-details" src={field} />
                                                            </div>
                                                        )
                                                    })


                                                }


                                            </div>

                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>

                </div>

            </div>
        </>
    )
}

export default MemberProfileView


// images: {
//     image_path: "https://digihelp.acuitysoftware.in/admin/storage/app/public/account_images/1724225267-5226.jpg"

// }
