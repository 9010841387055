import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import httpClient from "../../HTTPService";
import noImage from "../../assets/noimage.jpg"

function Income() {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search); // Parse query string
    const id = queryParams.get('id');
    const [accouuntDetaila, setaccountDetails] = useState()
    const [accountDetails, setAccouuntDetails] = useState()
    const navigate = useNavigate()

    useEffect(() => {
        getMembersDetailsView()
    }, [])

    const getMembersDetailsView = async () => {
        try {
            const result = await httpClient.post('/admin/members-view', { id: id }, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            console.log(result?.data.data, 'fjelkfjdkf')
            if (result.status === 200) {
                console.log(result.data?.data, 'data..')
                setaccountDetails(result.data?.data)
                setAccouuntDetails(result.data?.member_details)
                // setBusinessProfiles(result.data?.profiles)
                // setBankdetails(result.data?.bank_accounts[0])
                // setmemberlist(result?.data?.data)
            }

        } catch (error) {
            //   toast.error(error.message)
        }
    }
    return (
        <>
            <div className="header-back"
                onClick={() => navigate('/member', {
                    state: {
                        backpagi: location?.state?.paginationState
                    }
                })}
            >
                <div></div>
                <div className="back-button">
                    <i class="fa fa-arrow-left back-arrow-button"
                        // className="back-arrow-button"
                        aria-hidden="true"></i>
                </div>

            </div>
            <div className="row">

                <div className="card col-md-5">
                    <h5>Profile Details</h5>
                    <div className="acc-profile-image-section ">
                        {/* <div> */}
                        <p>Profile Image :</p>
                        {/* </div> */}
                        <div className="acc-details-image">
                            <div className="image-container">
                                <img
                                    className="image-acc-details"
                                    src={accouuntDetaila?.profile_photo_url !== null ?

                                        accouuntDetaila?.profile_photo_url : noImage
                                    }></img>
                            </div>

                            <div>

                                <span className={accouuntDetaila?.active == 1 ? "active_profile" : "inactive_profile"}>{accouuntDetaila?.active == 1 ? "Active" : "Inactive"}</span>
                            </div>
                        </div>
                    </div>
                    <div className="account-details">
                        <p>Name : </p>
                        <p className="ml-2">{accouuntDetaila?.full_name}</p>
                    </div>
                    {/* <div className="account-details">
                        <p>Email : </p>
                        <p className="ml-2"> {accouuntDetaila?.email}</p>
                    </div> */}
                    <div className="account-details">
                        <p>Phone : </p>
                        <p className="ml-2">{accouuntDetaila?.phone}</p>
                    </div>
                    <div className="addres-account-details">
                        <p>Address:
                            {`${accouuntDetaila?.state !== null ? `${accouuntDetaila?.state?.name},` : ''}
                         ${accouuntDetaila?.district !== null ? `${accouuntDetaila?.district?.district_name},` : ''} 
                         ${accouuntDetaila?.city !== null ? `${accouuntDetaila?.city},` : ''}
                         ${accouuntDetaila?.pin_code !== null ? `${accouuntDetaila?.pin_code}` : ''}`}
                        </p>
                    </div>
                    <div className="account-details">
                        <p>Position : </p>
                        <p className="ml-2">{accouuntDetaila?.role_name !== null ?
                            accouuntDetaila?.role_name : null}</p>
                    </div>

                </div>
                <div className=" col-md-6 ml-4">
                    <div className="row">
                        <div className="card income-view-box col-md-5">
                            <div>
                                <h6>Direct Member</h6>
                                <p className="member-butonborder"></p>
                            </div>

                            <p >Member: {accountDetails?.depth_1_total_members}</p>
                            <p>Per Member : {accountDetails?.depth_1_per_member_commission}</p>
                            <p>Total : {accountDetails?.depth_1_total_commission}</p>
                        </div>
                        <div className="card income-view-box2 col-md-5 ml-4">
                            <div>
                                <h6>Depth 2 Member</h6>
                                <p className="member-butonborder"></p>
                            </div>

                            <p>Member: {accountDetails?.depth_2_total_members}</p>
                            <p>Per Member : {accountDetails?.depth_2_per_member_commission}</p>
                            <p>Total : {accountDetails?.depth_2_total_commission}</p>
                        </div>

                    </div>

                </div>
            </div>


        </>
    )
}

export default Income