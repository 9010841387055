import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import httpClient from "../../HTTPService";
import { useState } from "react";
import './Subscription.css';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
const SubscriptionDetails = () => {
    const navigate = useNavigate();
    const [getsubDetail, setgetSubDetails] = useState();
    const [itemDetails, setItemDetails] = useState()
    const params = useParams();
    const { id } = params
    
    // Edit Subcription
    const editdata = (e) => {
        navigate('/howitsworkaddedit');
        const editWorks = JSON.stringify(e)
        JSON.stringify(localStorage.setItem('editSubData', editWorks))
    }

    // Get Subcription Details
    useEffect(() => {
        getSubcriptionDetails();
    }, [])
    const getSubcriptionDetails = async () => {
        try {
            const result = await httpClient.post("/admin/subscriptions/view", { id: id }, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (result.data.status) {

                setgetSubDetails(result.data.data);
            }
        } catch (error) {
            toast.error(error.message)
        }
    }

    // view details 
    function viewDetails(data) {
        document.getElementById('modalpop').classList.add('open')
        setItemDetails(data)
        // setdeleteyes(id)
    }
    function cancelModel() {
        document.getElementById('modalpop').classList.remove('open')
        setItemDetails('')
    }
    return (
        <div className="SubscriptionDetails">

            <div className="card">
                <div class="row">
                    <div className="col-md-12">
                        <div className="catImagettl">
                            <div className="actioncat">
                                <Link to="" className="editcat"><i class="fa fa-pencil" aria-hidden="true" onClick={() => { editdata(getsubDetail) }}></i></Link>
                                <Link to="/subscription" className="backcat" ><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>
                            </div>


                            <h2>{getsubDetail?.name}</h2>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="catDetails">
                            <div>
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>SL NO</th>
                                                <th>Plan Name</th>
                                                {/* <th>Validity</th>
                <th >Description</th> */}
                                                {/* <th>Discount</th>
                <th>Price</th> */}
                                                <th>Price</th>
                                                <th>Discounted Price</th>
                                                <th>Discounted Percentage (%)</th>
                                                <th>Validity</th>
                                                <th>Description</th>
                                                <th>Status</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                getsubDetail?.subscriptions?.map((item, i) => {

                                                    return (
                                                        <>
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                <td>{item?.name}</td>
                                                                <td>{item?.mrp}</td>
                                                                <td>{item?.price}</td>
                                                                <td>{item?.discount}</td>
                                                                <td>{item?.days}</td>
                                                                <td>
                                                                    <div>
                                                                        <i class="fa fa-eye"
                                                                            onClick={() => { viewDetails(item) }}
                                                                            aria-hidden="true"></i>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {/* </label> */}
                                                                    <span className={item?.active == 1 ? "active" : "inactive"}>
                                                                        {item?.active ? "Active" : "Inactive"}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                })

                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="modalpop" id="modalpop" >
                <div class="modalsbody">
                    <h5>{itemDetails?.name}</h5>
                    <div>
                        {itemDetails?.description}
                    </div>
                    <div class="popBtn">

                        <button type="button" class="cancelBtn" onClick={cancelModel}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default SubscriptionDetails;