import { useEffect, useState } from "react"
import httpClient from "../../HTTPService"

function ReviewList() {
    const [reviewList, setReviewlist] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [searchtext, setsearchtext] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [getLimit, setLimit] = useState(10);
    const items = Array.from({ length: totalCount }, (_, index) => `Item ${index + 1}`);
    const itemsPerPage = 10;
    const totalPages = Math.ceil(items.length / getLimit);
    const startPage = Math.max(1, currentPage - Math.floor(5 / 2));
    const endPage = Math.min(totalPages, currentPage + Math.floor(5 / 2));
    // const [totalCount, setTotalCount] = useState(0)
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    // State to manage the toggle status

    // Toggle function
    const handleToggle = () => {

    };
    const searchtxt = () => {
        getReviewList(searchtext, 1, getLimit);
    }
    //
    //pagination

    const Pagination = ({ currentPage, totalPages, onPageChange }) => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(i);
        }
        console.log(currentPage, 'current pages...')
        return (
            <div className="pagination">
                {currentPage > 1 && (
                    <button className="pagination-btn" onClick={() => onPageChange(currentPage - 1)}>
                        <i className="fa fa-chevron-left" aria-hidden="true"></i>
                    </button>
                )}
                {startPage > 1 && (
                    <button className="pagination-btn" onClick={() => onPageChange(1)}>
                        1
                    </button>
                )}
                {startPage > 2 && <span className="pagination-ellipsis">...</span>}
                {pages.slice(startPage - 1, endPage).map(page => (
                    <button
                        key={page}
                        className={`pagination-btn ${page === currentPage ? 'active' : ''}`}
                        onClick={() => onPageChange(page)}
                    >
                        {page}
                    </button>
                ))}
                {endPage < totalPages - 1 && <span className="pagination-ellipsis">...</span>}
                {endPage < totalPages && (
                    <button className="pagination-btn" onClick={() => onPageChange(totalPages)}>
                        {totalPages}
                    </button>
                )}
                {currentPage < totalPages && (
                    <button className="pagination-btn" onClick={() => onPageChange(currentPage + 1)}>
                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </button>
                )}
            </div>
        );
    };
    ///
    const updatestatus = (data) => {
        console.log(data, 'data...')
        try {
            httpClient.post('/admin/reviews-status-change', {
                id: data?.id,
                status: data?.status
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then((res) => {
                if (res.status === 200) {
                    getReviewList(searchtext, currentPage, getLimit)
                }
            })

            // if (result.status === 200) {
            //     console.log(result.data.data, 'data..')
            //     setTotalCount(result?.data.total_count)
            //     setReviewlist(result?.data?.data)
            // }

        } catch (error) {
            //   toast.error(error.message)
        }
    };
    useEffect(() => {
        getReviewList(searchtext, currentPage, getLimit)
    }, [getLimit, currentPage])
    const getReviewList = async (search_name, pagecount, limit) => {
        try {
            const result = await httpClient.post('/admin/reviews-list', {
                search_name, offset: pagecount, limit: limit
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })

            if (result.status === 200) {
                console.log(result.data.data, 'data..')
                setTotalCount(result?.data.total_count)
                setReviewlist(result?.data?.data)
            }

        } catch (error) {
            //   toast.error(error.message)
        }
    }
    // switch button


    return (

        <>
            <div class="search-box">
                <div class="searchtct">

                    <i class="fa fa-search" aria-hidden="true"></i>
                    <input type="text" name="text" placeholder="Search..."
                        onChange={(val) => setsearchtext(val.target.value)}
                        onKeyDown={() => {
                            if (searchtext === '') {
                                getReviewList(searchtext, currentPage, getLimit)
                            }
                        }}
                    />
                    <button type='button' className='searchBtn' onClick={searchtxt} >Search</button>


                </div>

            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>SL NO</th>
                                    <th> User Name</th>
                                    <th>User Roll</th>
                                    <th>Comments</th>
                                    <th>Rating</th>

                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                               
                                {
                                    reviewList?.map((item, index) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.user?.name}</td>
                                                    <td>{item?.user?.role_name}</td>
                                                    <td>{item?.comment}</td>
                                                    <td>

                                                        <div className="star-rating">
                                                            {Array.from({ length: 5 }, (_, index) => (
                                                                <span
                                                                    key={index}
                                                                    className={`star ${index < item?.rating ? 'filled' : ''}`}
                                                                //   onClick={() => handleClick(index + 1)}
                                                                >
                                                                    ★
                                                                </span>
                                                            ))}
                                                        </div>

                                                    </td>
                                                    <td>

                                                        <div
                                                            className={`switch ${item?.active === 1 ? 'on' : 'off'}`}
                                                            onClick={() => updatestatus(
                                                                {
                                                                    id: item?.id,
                                                                    status: item?.active === 1 ? 0 : 1
                                                                }
                                                            )}
                                                            role="switch"
                                                            aria-checked={item?.active}
                                                            aria-label="Toggle switch"
                                                        >
                                                            <div className="switch-handle" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        <div className='nodata-found-div'>
                           {
                                    reviewList?.length == 0 ?
                                    <p>No Data Found</p>
                                        //  <img 
                                        //  className='table-image'
                                        //  src={require('../../assets/OIP.png')}/>
                                        : null
                                }
                           </div>
                    </div>
                </div>
            </div>
            <div class="tablepgntn">
                <p>Show <select value={getLimit} onChange={(val) => { 
                    setLimit(val.target.value);
                    setCurrentPage(1)
                    }}>
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                    <option>50</option>
                </select> Entries</p>
                <ul>

                </ul>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            </div>
        </>
    )
}

export default ReviewList