import { useParams } from "react-router-dom";
import { useEffect } from "react";
import httpClient from "../../HTTPService";
import { ToastContainer, toast } from 'react-toastify';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
const UserEdit = ()=>{
    const navigate = useNavigate()
    const [editUser, setEditUser] = useState();
    const [UserName, setUserName] = useState('');
    const [isSubmitting, setisSubmitting] = useState(false)
    useEffect(()=>{
        let getLocal = localStorage.getItem('editUsers')
        let editUsers = JSON.parse(getLocal) 
        setEditUser(editUsers);
        setUserName(editUsers?.name)

         
       
         
      
    },[]);

    // Edit Name
    const userEdit = async ()=>{
        if(isSubmitting) return;
        setisSubmitting(true)
        try{
            const result = await httpClient.post('/admin/users/edit',{id:editUser?.id,name:UserName},{
             headers: {
               "Content-Type": "application/json",
               "Accept": "application/json",  
               'Authorization': `Bearer ${localStorage.getItem('token')}`     
            }
            });
            console.log(result.data.status);
            
            if(result.data.status == true){
                setTimeout(()=>{
                    navigate('/alluser')
                },1000)
            toast.success("User Update Successfully!")

            }
            if(result.data.status == false){
                toast.error(result.data.message)
            }
          }catch(error){
            toast.error(error.message)
          }finally{
            setisSubmitting(false)
          }
    }
    return(
        <div className="userEdit">
                  <ToastContainer 
                  autoClose={1000}
                  />
            <div className="card">
                <div className="row">
                <div className="col-md-4">
                 <div className="formGroup">
                    <label>Name</label>
                    <input
      type="text"
      name="name"
      placeholder="Name"
      onChange={(val) => setUserName(val.target.value)}
      value={UserName} // Controlled input
      required
    />

                   
                 </div>
                </div>
                <div className="col-md-4">
                 <div className="formGroup">
                    <label>Email</label>
                    <input type="text" value={editUser?.email? editUser?.email:"Email ID"} readOnly/>
                 </div>
                </div>
                <div className="col-md-4">
                 <div className="formGroup">
                    <label>Phone Number</label>
                    <input type="text" value={editUser?.phone} readOnly/>
                 </div>
                </div>
                <div className="col-md-4">
                <div className='btnFlx'>
                    <button type='button' className='addBtn'onClick={()=>{navigate('/alluser');localStorage.removeItem('editUsers')}}>Cancel</button>
                    <button type='button' className='addBtn' onClick={userEdit} disabled={isSubmitting}>Update</button>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}
export default UserEdit;