import React, { useContext, useEffect, useRef, useState } from 'react';
import toggle from '../../assets/toggle.png';
import user from '../../assets/user.png';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LayoutContext } from '../Layout';
const Header = () => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  const navigate = useNavigate();
  const [pageTitle, setpageTitle] = useState();
  const [open, setOpen] = useState(false);
  let downRef = useRef();
  const { tooglemenu, settooglemenu } = useContext(LayoutContext);
  useEffect(() => {
    let handler = (e) => {
      if (!downRef.current.contains(e.target)) {
        setOpen(false)
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    }
  }, []);
  const logOut = () => {
    localStorage.clear();
    toast.success('Successfully Logout !')
    navigate('/')
  }
  // context
  // setpageTitle(window.location.pathname.slice(1))
  return (
    <header>
      <div className="header-left">
        {/* <Link to="/"> */}
        <div onClick={() => {settooglemenu(!tooglemenu)}} 
          className='toogle-menuopen'
          >
          <img src={toggle} />
        </div>

        {/* </Link> */}

        <h2>{pageTitle}</h2>
      </div>
      <div className="header-right">
        <div className="user">
          <span>
            <img src={user} />
          </span>
          <div className="userdtls" ref={downRef}>
            <label>{userData?.full_name}</label>
            <b onClick={() => { setOpen(!open) }}><i className="fa fa-chevron-down" aria-hidden="true"></i></b>

            <small className={open ? "open" : ""} onClick={logOut}>Logout</small>
          </div>
        </div>

      </div>
    </header>
  )
}
export default Header;