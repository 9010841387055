import './Cms.css';
import { ToastContainer, toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import httpClient from '../../HTTPService';
import { Link, useNavigate } from 'react-router-dom';

const Cms = () => {
  const [videoUrl, setVideoUrl] = useState();
  const [cmsList, setcmsList] = useState([])
  const navigate = useNavigate()
  useEffect(() => {
    gteCmsList()
  }, [])
  const uploadHomeVideo = async () => {
    document.getElementById('modalpop').classList.add('open')

  }
  const closeBtn = () => {
    document.getElementById('modalpop').classList.remove('open')
  }
  //cms list
  const gteCmsList = async () => {
    // try{
    const result = await httpClient.post('/admin/cms-list', {}, {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }).then((res) => {
      if (res.status === 200) {
        setcmsList(res?.data?.data)
      }
      console.log(res.data)
    });

    //  if(result.data.status == true){
    //   toast.success('Video Update Successfully!');
    //   setVideoUrl('');
    //  }
    //  if(result.data.status == false){
    //   toast.error(result.data.message)
    //  }


  }
  const uploadVideo = async () => {
    try {
      const result = await httpClient.post('/admin/home-cms/update', { video_link: videoUrl }, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (result.data.status == true) {
        toast.success('Video Update Successfully!');
        setVideoUrl('');
        document.getElementById('modalpop').classList.remove('open')
      }
      if (result.data.status == false) {
        toast.error(result.data.message)
      }
    }
    catch (error) {
      toast.error(error.message)
    }
  }
  return (
    <div className="">
      <ToastContainer  
      autoClose={1000}
      />
      <div className="row">
        <div className='col-md-12'>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>SL No</th>
                  <th>Name</th>
                  <th>Image</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {/* <td> */}
                {
                  cmsList?.map((item, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>  {index + 1}</td>
                          <td>{item?.title}</td>
                          <td>
                            <div>
                              {
                                item?.video_link != null ?
                                  <img
                                    className='cms-image'
                                    src={ require('../../assets/watch-movie.png')}
                                  />
                                  :
                                  <img
                                    className='cms-image'
                                    src={item?.image_path !== null ? item?.image_path : require('../../assets/noimage.jpg')}
                                  />
                              }

                            </div>
                          </td>
                          <td>
                            <div class="action">
                              {/* <Link><i class="fa fa-user" aria-hidden="true"></i></Link>
                          <Link><i class="fa fa-lock" aria-hidden="true"></i></Link> */}
                              {
                                item?.title === 'Home' ?
                                  <div className="upload-icon" onClick={uploadHomeVideo}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
                                      <path fill-rule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708z" />
                                      <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383m.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                                    </svg>
                                  </div>
                                  : null
                                // <Link 
                                //   // onClick={() => { deleteBtn(item?.id) }}
                                //   >
                                //     <i class="fa fa-pencil" aria-hidden="true" 

                                //     ></i>
                                //     </Link>

                              }

                              <Link
                              // onClick={() => { deleteBtn(item?.id) }}
                              >
                                <i class="fa fa-pencil"
                                  onClick={() => navigate(item?.title === 'Home' ?
                                    `/homecms-details?id=${item?.id}` : `/aboutus-cms?id=${item?.id}`

                                  )}
                                  aria-hidden="true"></i>
                              </Link>

                            </div>
                          </td>
                        </tr>
                      </>
                    )
                  })
                }
                {/* </td> */}
              </tbody>
            </table>
          </div>
          {/* <div className='col-md-4'>
             <div className='formGroup'>
                <label>Upload Video URL</label>
                <input type='text' value={videoUrl} placeholder='Video URL' onChange={(val)=>setVideoUrl(val.target.value)}/>
             </div>
            </div> */}
          {/* <div className='col-md-4'>
              <label>&nbsp;</label><br/>
                <button type='button' className='searchBtn' onClick={uploadVideo}>Upload</button>
            </div> */}
        </div>
      </div>

      <div className="modalpop" id="modalpop">
        <div className="modalsbody">
          <h5>Home Page Video Upload</h5>
          <div className='row'>
            <div className='col-md-12'>
              <div className='formGroup'>
                {/* <label>Upload Video URL</label> */}
                <input type='text' value={videoUrl} placeholder='Video URL' onChange={(val) => setVideoUrl(val.target.value)} />
              </div>
            </div>
          </div>
          <div className="popBtn">
            <button type="button" className="secussBtn" onClick={uploadVideo}>
              Upload
            </button>
            <button type="button" className="cancelBtn" onClick={closeBtn}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Cms;