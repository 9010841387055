import { useLocation, useNavigate } from "react-router-dom";
import httpClient from "../../HTTPService";
import { useEffect, useState } from "react";
import noimage from '../../assets/noimage.jpg'

function MemberTeam() {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search); // Parse query string
    const id = queryParams.get('id');
    const [accouuntDetaila, setaccountDetails] = useState()
    const [accountDetails, setAccouuntDetails] = useState()
    const navigate =useNavigate()
    useEffect(() => {
        getMembersDetailsView()
    }, [])
    const getMembersDetailsView = async () => {
        try {
            const result = await httpClient.post('/admin/members-view', { id: id }, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            if (result.status === 200) {
                console.log(result.data?.member_details, 'data..')
                setaccountDetails(result.data?.data)
                setAccouuntDetails(result.data?.member_details)
                // setSubscriptionList(result.data?.subscriptions)
            }

        } catch (error) {
            //   toast.error(error.message)
        }
    }
    return (
        <>
         <div className="header-back"
                onClick={() => navigate('/member', {
                    state: {
                        backpagi: location?.state?.paginationState
                    }
                })}
            >
                <div></div>
                <div className="back-button">
                    <i class="fa fa-arrow-left back-arrow-button"
                        // className="back-arrow-button"
                        aria-hidden="true"></i>
                </div>

            </div>
            <div className="row">
                <div className="card col-md-5 ">
                    <div className="team-details-heading">
                        <h5>Profile Details</h5>
                        <div className="row" >
                            {/* <p className="total-numbers-team">Total Members : {accountDetails?.total_members} </p> */}
                            <p className="total-numbers-team">Total Income :  
                                
                            <span className="price-tag">
                                    <img 
                                    className="price-image"
                                    src={require('../../assets/th(1).png')}/>
                                </span>
                                {accountDetails?.total_income}
                               
                                 </p>

                        </div>
                    </div>
                    <div className="acc-profile-image-section ">
                        {/* <div> */}
                        <p>Profile Image :</p>
                        {/* </div> */}
                        <div className="acc-details-image">
                            <div className="image-container">
                                <img
                                    className="image-acc-details"
                                    src={accouuntDetaila?.profile_photo_url !== null ?

                                        accouuntDetaila?.profile_photo_url : noimage
                                    }></img>
                            </div>

                            <div>

                                <span className={accouuntDetaila?.active == 1 ? "active_profile" : "inactive_profile"}>{accouuntDetaila?.active == 1 ? "Active" : "Inactive"}</span>
                            </div>
                        </div>
                    </div>
                    <div className="account-details">
                        <p>Name : </p>
                        <p className="ml-2">{accouuntDetaila?.full_name}</p>
                    </div>
                    {/* <div className="account-details">
                        <p>Email : </p>
                        <p className="ml-2"> {accouuntDetaila?.email}</p>
                    </div> */}
                    <div className="account-details">
                        <p>Phone : </p>
                        <p className="ml-2">{accouuntDetaila?.phone}</p>
                    </div>
                    <div className="addres-account-details">
                        <p>Address:
                            {`${accouuntDetaila?.state !== null ? `${accouuntDetaila?.state?.name},` : ''}
                         ${accouuntDetaila?.district !== null ? `,${accouuntDetaila?.district?.district_name}` : ''} 
                         ${accouuntDetaila?.city !== null ? `,${accouuntDetaila?.city}` : ''}
                         ${accouuntDetaila?.pin_code !== null ? `,${accouuntDetaila?.pin_code}` : ''}`}
                        </p>
                        {/* <p className="ml-2">{accouuntDetaila?.address}</p> */}
                        {/* {
                            accouuntDetaila?.state !== null && accouuntDetaila?.state !== undefined ?

                                <div className="addres-section">
                                    <p>State: </p>
                                    <p className="ml-2">{accouuntDetaila?.state}</p>
                                </div>
                                :
                                null
                        }
                        {
                            accouuntDetaila?.district !== null ?

                                <div className="addres-section">
                                    <p>District: </p>
                                    <p className="ml-2">{accouuntDetaila?.district}</p>
                                </div>
                                :
                                null
                        }
                        <div className="addres-section">
                            <p>City/Villiage: </p>
                            <p className="ml-2">{accouuntDetaila?.city}</p>
                        </div>
                        <div className="addres-section">
                            <p>PIN Code : </p>
                            <p className="ml-2">{accouuntDetaila?.pin_code}</p>
                        </div> */}
                    </div>
                    <div className="account-details">
                        <p>Position : </p>
                        <p className="ml-2">{accouuntDetaila?.role_name !== null ?
                            accouuntDetaila?.role_name : null}</p>
                    </div>

                </div>
                <div className="col-md-6 ml-5">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="team-heading row">
                                <p className="ml-3">Direct Members: {accountDetails?.total_depth_1_members}</p>
                                <p className="mr-2">Total Commision : {accountDetails?.depth_1_total_commission}</p>
                            </div>
                            <div className="box-teammember row p-3 mt-2">
                                <div className="box-team-acc col-md-5">
                                    <div>Unpaid Member : {accountDetails?.total_depth_1_unpaid_members}</div>
                                </div>
                                <div className="box-team-acc col-md-5 ml-3">
                                    <div>Paid Member : {accountDetails?.total_depth_1_paid_members}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mt-5 ">
                            <div className="team-heading row">
                                <p className="ml-3">Depth 2 Members : {accountDetails?.total_depth_2_members}</p>
                                <p className="mr-2">Total Commision : {accountDetails?.depth_2_total_commission}</p>
                            </div>
                            <div className="box-teammember row p-3 mt-2">
                                <div className="box-team-acc col-md-5">
                                    <div>Unpaid Member : {accountDetails?.total_depth_2_unpaid_members}</div>
                                </div>
                                <div className="box-team-acc col-md-5 ml-3">
                                    <div>Paid Member : {accountDetails?.total_depth_2_paid_members}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mt-5 ">
                            <div className="team-heading row">
                                <p className="ml-3">Total Members : {accountDetails?.total_members}</p>
                                <p className="mr-2">Total Commision : {accountDetails?.total_income}</p>
                            </div>
                            <div className="box-teammember row p-3 mt-2">
                                <div className="box-team-acc col-md-5">
                                    <div>Unpaid Member : {accountDetails?.total_unpaid_members}</div>
                                </div>
                                <div className="box-team-acc col-md-5 ml-3">
                                    <div>Paid Member : {accountDetails?.total_paid_members}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default MemberTeam