import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import httpClient from "../../HTTPService";
import { ToastContainer, toast } from 'react-toastify';

function AbountUs() {

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search); // Parse query string
    const id = queryParams.get('id');
    const [cmsDetails, setcmsDetails] = useState()
    const [imagePrvw, setimagePrvw] = useState("");
    const [imageUpload, setImageUpload] = useState('')
    const [description, setdescription] = useState('')

    const validateImageDimensions = (file) => {
        const img = new Image();
        const reader = new FileReader();

        reader.onload = (e) => {
            img.src = e.target.result;
        };

        img.onload = () => {
            const { width, height } = img;
            console.log(width, height, 'image data..')
            const maxWidth = 300; // Example max width
            const maxHeight = 168; // Example max height

            if (width !== maxWidth || height !== maxHeight) {
                toast.error(`Image dimensions should be ${maxWidth}x${maxHeight} or smaller.`)
                // setImage(null);
            } else {

                setimagePrvw(URL.createObjectURL(file))

            }
        };

        reader.readAsDataURL(file);
    };

    useEffect(() => {
        getCmsDetails()
    }, [])
    const uploadcat = (e) => {
        console.log(e.target.files[0], 'mfiddfs')
        setImageUpload(e.target.files)
        // validateImageDimensions(e.target.files[0])
        setimagePrvw(URL.createObjectURL(e.target.files[0]))

    }
    // details
    const getCmsDetails = async () => {

        const result = await httpClient.post('/admin/cms-view', { id: id }, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res) => {
            if (res.status === 200) {
                setcmsDetails(res?.data?.data)
                setimagePrvw(res?.data?.data?.image_path)
                setdescription(res?.data?.data?.description)
            }
            console.log(res.data)
        });


    }

    function UpdateaboutCms() {
        if (cmsDetails?.title === 'My Income') {
            const formData = new FormData();
            formData.append('image_1', imageUpload[0]);
            // let data = {
            //     id: id,
            //     description: description,
            //     image_1: imageUpload[0]

            // }
            console.log(id, description, imageUpload[0], 'payload data....')
            const result = httpClient.post('/admin/my-income-cms/update', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Accept": "multipart/form-data",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then((res) => {
                if (res.status === 200) {
                    toast.success(res.data.message)
                }
                console.log(res.data)
            });
        } else if (cmsDetails?.title === 'My Member') {
            const formData = new FormData();
            formData.append('image_1', imageUpload[0]);
            // let data = {
            //     id: id,
            //     description: description,
            //     image_1: imageUpload[0]

            // }
            console.log(id, description, imageUpload[0], 'payload data....')
            const result = httpClient.post('/admin/my-member-cms/update', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Accept": "multipart/form-data",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then((res) => {
                if (res.status === 200) {
                    toast.success(res.data.message)
                }
                console.log(res.data)
            });
        } else if (cmsDetails?.title === 'Income Structure') {
            const formData = new FormData();
            formData.append('image_1', imageUpload[0]);

            console.log(id, description, imageUpload[0], 'payload data....')
            const result = httpClient.post('/admin/income-structure-cms/update', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Accept": "multipart/form-data",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then((res) => {
                if (res.status === 200) {
                    toast.success(res.data.message)
                }
                console.log(res.data)
            });
        } else {
            const formData = new FormData();
            formData.append('id', id);
            formData.append('description', description);
            formData.append('image_1', imageUpload[0]);

            console.log(id, description, imageUpload[0], 'payload data....')
            const result = httpClient.post('/admin/about-us-cms/update', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Accept": "multipart/form-data",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then((res) => {
                if (res.status === 200) {
                    toast.success(res.data.message)
                }
                console.log(res.data)
            });
        }

    }

    return (
        <>
            <ToastContainer 
            autoClose={1000}
            />
            <div className="row">
                <div className="col-md-12 card">
                    <h5>{cmsDetails?.title}</h5>
                    <div className=" about-sectons ">
                        <div className="">
                            <img
                                className="aboutus-image"
                                src={imagePrvw ? imagePrvw : require('../../assets/noimage.jpg')}
                            />
                            <span className="uploadImage ml-5"
                                onChange={(e) => { uploadcat(e) }}

                            >
                                <input type="file" name="image"
                                    accept=".jpg,.jpeg,.png"
                                />
                                <p className="uploade-text">Upload Image(.png,.jpeg,.jpg) </p>

                                <div className="upload-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708z" />
                                        <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383m.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                                    </svg>
                                </div>
                                {/* <p className="image-dimentiontext">300 x 180</p> */}
                            </span>

                        </div>
                        {
                            (cmsDetails?.title !== 'My Member' && cmsDetails?.title !== 'My Income' && cmsDetails?.title !== 'Income Structure') ?
                                <div className=" about-cms ml-4">
                                    <label>Description</label>
                                    <textarea
                                        value={description
                                        }
                                        onChange={(val) => setdescription(val.target?.value)}
                                        className="aboutus-textarea"
                                    />
                                </div>
                                : null
                        }

                    </div>

                    <div className="btngrp">
                        {/* <button type="button" className="themeBtn cancelBtn" onClick={() => { navigate('/category'); localStorage.removeItem('editData') }}>Cancel</button> */}
                        <button type="button" className="themeBtn"
                            onClick={() => UpdateaboutCms()}
                        // disabled={isSubmitting}
                        >Update</button>
                    </div>

                </div>
            </div>
        </>
    )
}
export default AbountUs