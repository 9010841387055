import { useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import httpClient from "../../HTTPService";
import { ToastContainer, toast } from 'react-toastify';
import noImage from "../../assets/noimage.jpg";
import { useEffect } from "react";
import './HowItsWork.css';
const HowItsWorkAddEdit = () => {
    const navigate = useNavigate();
    const [categotyName, setCategotyName] = useState("");
    const [catStatus, setStatus] = useState("");
    const [catImage, setcatImage] = useState("");
    const [imagePrvw, setimagePrvw] = useState();
    const [itemId, setitemId] = useState('');
    const [editMode, seteditMode] = useState(false)
    const location = useLocation()
    const [video, setviddeo] = useState('')
    const queryParams = new URLSearchParams(location.search); // Parse query string
    const id = queryParams.get('id');

    // Edit How Work
    useEffect(() => {
        if (id != undefined) {

            try {
                httpClient.post("/admin/works/view", { id: id }, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Accept": "application/json",
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }).then((res) => {
                    if (res.status === 200) {
                        setCategotyName(res?.data?.data?.title)
                        setimagePrvw(res?.data?.data?.video_path)
                        setviddeo(res?.data?.data?.video)
                        setitemId(res?.data?.data?.id)
                        setStatus(res?.data?.data.active)

                    }
                });

            } catch (error) {
                // toast.error(error.message)
            }

        }
        console.log(id,'queryt params')
       

    }, [id])
    // upload Image
    const uploadcat = (e) => {
        setcatImage(e.target.files)
        console.log(e.target.files[0], 'video uploaded,,');
        setimagePrvw(URL.createObjectURL(e.target.files[0]))
    }
    const addCatform = async (event) => {
        event.preventDefault();
        if (id != undefined) {
            console.log(imagePrvw, 'image.....prew..')
            const formData = new FormData();
            const dummyData = new Uint8Array(31984531);
            const parts = video != null ? video.split('/') : null;
            let _actualfilemp4 = parts != null ? parts.pop() : '';
            // const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
            // const response = await fetch(proxyUrl + imagePrvw, {
            //     headers: {
            //         'Authorization': `Bearer ${localStorage.getItem('token')}`
            //     }
            // });
            // console.log(response)
            // const blob = await response.blob();
            // console.log(blob, 'blob .kfdklfmdkm')
            // console.log(_actualfilemp4, 'actual path...')
            // const blob = new Blob([dummyData], { type: 'video/mp4', });
            // console.log(blob, _actualfilemp4, 'blob test...')
            // const file = new File([dummyData], _actualfilemp4, {
            //     type: 'video/mp4',
            //     lastModified: Date.now(), // You can use a timestamp or other value
            //     // size: 31984531
            // });

            // console.log(file, 'image file')
            formData.append('id', id);
            formData.append('title', categotyName);
            formData.append('active', catStatus);
            formData.append('video', catImage[0]);

            // console.log(formData, 'form data... payload..')
            try {
                const result = await httpClient.post("/admin/works/update", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Accept": "multipart/form-data",
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                })
                if (result.data.status) {
                    toast.success(result.data.message);

                    // localStorage.removeItem('editWorkData')
                    navigate('/howitswork')
                    // setimagePrvw(null);
                    setCategotyName("")
                    document.getElementById('addForm').reset();
                   

                }

                if (result.data.status == false) {
                    toast.error(result.data.message)
                }
            }
            catch (error) {
                toast.error(error.message)
            }
        }
        else {
            const formData = new FormData();
            formData.append('title', categotyName);
            formData.append('active', catStatus);
            formData.append('video', catImage[0]);
            try {
                const result = await httpClient.post("/admin/works/create", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Accept": "multipart/form-data",
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }

                });
                navigate('/howitswork')
                setimagePrvw(null);
                setCategotyName("")
                document.getElementById('addForm').reset();
                toast.success(result.data.message)
                
            }
            catch (error) {
                toast.error(error.message)

            }
        }

    }
    return (
        <div className="howitsWorks">
            <ToastContainer 
            autoClose={1000}
            />
            <div className="card">
                <form onSubmit={addCatform} id="addForm">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formGroup flxfrm">
                                <div className="catIcon">
                                    {imagePrvw ?
                                        <video src={imagePrvw} controls accept="video/mp4" /> : <img src={require('../../assets/novideo.png')} />
                                    }



                                </div>

                                <span className="uploadImage" onChange={(e) => { uploadcat(e) }}><input type="file" name="image" /><i class="fa fa-cloud-upload" aria-hidden="true"></i></span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="formGroup">
                                <label>Title</label>
                                <input type="text" name="categotyName" placeholder="Name" onChange={(val) => setCategotyName(val.target.value)} value={categotyName} required />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="formGroup">
                                <label>Status</label>
                                <select value={catStatus} onChange={(val) => setStatus(val.target.value)} >
                                    <option>Select Status</option>
                                    <option value="1">Active</option>
                                    <option value="0">Inactive</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="btngrp">
                                <button type="button" className="themeBtn cancelBtn" onClick={() => { navigate('/howitswork'); localStorage.removeItem('editWorkData') }}>Cancel</button>
                                <button type="submit" className="themeBtn">{id != undefined ? "Update" : "Submit"}</button>
                            </div>

                        </div>
                    </div>

                </form>
            </div>
        </div>
    )

}
export default HowItsWorkAddEdit;