import { useEffect, useMemo, useState } from "react"
import httpClient from "../../HTTPService"
import { ToastContainer, toast } from 'react-toastify';

function ChangePassword() {
    const [formData, setFormData] = useState({
        old_password: '',
        password: '',
        password_confirmation: ''
    })
    const [ismatch, setIsmatch] = useState(false)
    const [islength, setislength] = useState(false)
    useMemo(() => {
        if (formData?.password !== formData?.password_confirmation) {
            setIsmatch(true)
        } else {
            setIsmatch(false)
        }
    }, [formData?.password_confirmation])

    useMemo(() => {
        if (formData?.password.length < 6 && formData?.password !== '') {
            setislength(true)

        } else {
            setislength(false)
        }
    }, [formData?.password])
    function SubmitData() {
        httpClient.post('/admin/change-password', JSON.stringify(formData), {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res) => {
            if (res.status === 200) {
                toast.success(res?.data?.message)
            } else {
                toast.error(res?.data?.message)

            }
        })


    }
    return (
        <>
            <ToastContainer
                autoClose={1000}
            />
            <div className="change-pass-foem-container">
                <form className="card">
                    <div className="formGroup">
                        <label>Old Password</label>
                        <input type="text"
                            value={formData?.old_password}
                            placeholder="Enter Old Password"
                            onChange={(val) => {
                                setFormData(pre => (
                                    {
                                        ...pre,
                                        old_password: val.target.value
                                    }
                                ))
                            }}

                        />
                    </div>
                    <div className="formGroup">
                        <label>New Password</label>
                        <input type="text"
                            value={formData?.password}
                            placeholder="Enter New Password"
                            onChange={(val) => {
                                setFormData(pre => (
                                    {
                                        ...pre,
                                        password: val.target.value
                                    }
                                ))
                            }}

                        />
                        {
                            islength ? <p className="password-not-match">Password Must be 6 Alpha Numeric</p> : null
                        }
                    </div>
                    <div className="formGroup">
                        <label>Confirm Password</label>
                        <input type="text"
                            value={formData?.password_confirmation}
                            placeholder="Re-enter Password"
                            onChange={(val) => {

                                setFormData(pre => (
                                    {
                                        ...pre,
                                        password_confirmation: val.target.value
                                    }
                                ))


                            }}

                        />
                        {
                            ismatch ? <p className="password-not-match">Password Not Match</p> : null
                        }

                    </div>
                    <div className="formGroup changepass-button">
                        <label>&nbsp;</label>
                        <button
                            onClick={() => SubmitData()}
                            disabled={(formData?.password_confirmation === '' || formData?.password === '' || formData?.reenterpassword === '') ? true : false}
                            type="button" className="addBtn">Submit</button>
                    </div>

                </form>
            </div>
        </>
    )
}

export default ChangePassword